import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
//import App from './App';
import reportWebVitals from './reportWebVitals';
import { StyleRoot } from "radium"
import StoreProvider from "./contexts/application/Provider";
import {Authentication} from "./newScreen/Authentication";
import Dashboard from "./newScreen/centre/Dashboard";
import { BrowserRouter as Router, Routes, Route, Link, HashRouter  } from "react-router-dom";
import Admin from "./newScreen/admin/Admin";
//import Groupe from "./newScreen/groupes/Groupes";
import Facture from "./newScreen/facture/Facture";
import Factureinvoicline from "./newScreen/facture/Factureinvoicline";
import Commande from "./newScreen/commande/Commande";
import Ordrspline from "./newScreen/commande/Ordrspline";
import Desadv from "./newScreen/commande/desadv";
import Desadvline from "./newScreen/commande/Desadvline";
import Z from "./newScreen/commande/Desad_z";
import Desad_z_colis from "./newScreen/commande/Desad_z_colis";
import WMSOrderTrans from "./newScreen/commande/Desad_z_WMSOrderTrans";
import InventTable from "./newScreen/commande/Desad_z_WMSOrderTrans_InventTable";
import Message from "./newScreen/message/Message";
import Groupe from "./newScreen/groupes/Groupes";
import { CookiesProvider } from 'react-cookie';

import './i18n';
import { Centredownfacture } from './newScreen/facture/Centredownfacture';

const PrivateRouters = ()  => {
    return (
        <Routes>
            <Route index element={<Dashboard />} />
            <Route path="/admin" element={<Admin />} />
            {/* <Route path="/groupe" element={<Groupe />} /> */}
            <Route path="/facture" element={<Facture />} />
            <Route path="/facture/invoicline" element={<Factureinvoicline />} />
            <Route path="/commande" element={<Commande />} />
            <Route path="/commande/ordrspline" element={<Ordrspline />} />
            <Route path="/commande/desadv" element={<Desadv />} />
            <Route path="/commande/desadv/desadvline" element={<Desadvline />} />
            <Route path="/commande/desadv/z" element={<Z />} />
            <Route path="/commande/desadv/z/colis" element={<Desad_z_colis />} />
            <Route path="/commande/desadv/z/WMSOrderTrans" element={<WMSOrderTrans />} />
            <Route path="/commande/desadv/z/WMSOrderTrans/InventTable" element={<InventTable />} />
            <Route path="/message" element={<Message />} />
            <Route path="/groupe" element={<Groupe />} />
            <Route path="/facture/down" element={<Centredownfacture />} />
        </Routes>
    )
};

ReactDOM.render(
    <React.StrictMode>
        <CookiesProvider defaultSetOptions={{ path: '/' }}>
        <Router >
            <Routes >
                <Route path="/*" element={<Authentication />} />
                 <Route
                    path="/dashboard/*"
                    element={
                        <PrivateRouters />
                    }
                /> 
            </Routes>
        </Router>
        </CookiesProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
  
  reportWebVitals();
  