import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField/TextField";
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import Button from "@mui/material/Button";
import {Alert} from "../Authentication/index";
import SaveIcon from '@mui/icons-material/Save';
import FormControlLabel from '@mui/material/FormControlLabel';
import {helpers} from "../../services/api/helpers";
import Chip from '@mui/material/Chip';
import ls from "local-storage";
import Snackbar from "@mui/material/Snackbar/Snackbar";
import {ThemeProvider} from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import LoadingOverlay from "react-loading-overlay";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import { useLocation, useNavigate } from "react-router-dom"
// import Shop2 from '@mui/icons-material/Shop2';
// import Square from '@mui/icons-material/Square';
// import Stadium from '@mui/icons-material/Stadium';
// import Snooze from '@mui/icons-material/Snooze';
// import Store from '@mui/icons-material/Store';
// import Tapas from '@mui/icons-material/Tapas';
// import TenMp from '@mui/icons-material/TenMp';
import Delete from '@mui/icons-material/Delete';
import Dialog from "@mui/material/Dialog/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import { useTranslation } from "react-i18next";
import {Outsidecomponenti18n} from "../shared/Outsidecomponenti18n";

// import Timelaplse from '@mui/icons-material/Timelaplse';
// import Timer10 from '@mui/icons-material/Timer10';
// import Toc from '@mui/icons-material/Toc';
// import Toll from '@mui/icons-material/Toll';
// import Tour from '@mui/icons-material/Tour';
// import Trafic from '@mui/icons-material/Trafic';
// import Tram from '@mui/icons-material/Tram';
// import Troubleshoot from '@mui/icons-material/Troubleshoot';
// import Try from '@mui/icons-material/Try';
// import Upcoming from '@mui/icons-material/Upcoming';
// import Villa from '@mui/icons-material/Villa';
// import Widgets from '@mui/icons-material/Widgets';
// import Work from '@mui/icons-material/Work';
// import Yard from '@mui/icons-material/Yard';




const columns = ((clickHandler, ShowConfirmDelete) => [

    {
        name: Outsidecomponenti18n(`groupe.identification`),
        selector: row => row.identificationgroupes.map((item)=>{
            if(item.identification)
            return item.identification + (', ')
            else
            return ""
        }),
        wrap:true,
        sortable:true,
    },
    {
        name: Outsidecomponenti18n(`groupe.nom`),
        selector: row => row.nom,
        sortable:true,
        wrap:true,
        style:{fontSize:16}
    },
    {
        name: Outsidecomponenti18n(`groupe.commentaire`),
        selector: row => row.commentaire,
        wrap:true,
    },

    {
        name: '',
        wrap:true,
        button: true,
        cell: (row) => <div style={{ }}><div  onClick={ShowConfirmDelete} id={row.id} style={{fontSize:11, color: 'red'}}>{Outsidecomponenti18n(`groupe.supprimer`)}<Delete /></div></div>,}
]);


export const Groupe = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { i18n } = useTranslation();
    const { t } = useTranslation();

    const [isshowmodal, setshowmodal] = useState(false);
    const [isshowmodalmodif, setshowmodalmodif] = useState(false);
    const [isLoadingSave, setLoadingSave] = useState(false);
    const [openSnack, setOpenSnack] = useState({
        isOpenNomError:false,
        txt:''});
    const [openToast, setOpenToast] = useState({isOpenToast:false, txt:''});
    const [tables, setTables] = React.useState([]);
    const [isLoading, setLoading] = React.useState(false);
    const [searchField, setSearchField] = useState("");
    const [nommodif, setnommodif] = useState("");
    const [idmodif, setidmodif] = useState(null);
    const [commentairemodif, setcommentairemodif] = useState("");
    const [idtosuppr, setIdToSuppr] = useState(null);
    const [identificationgroupes, setIdentificationgroupes] = useState([]);
    const [arrayitemgroupe, setArrayItemgroupe] = useState([]);
    const [groupe, setGroupe] = useState("");
    const [open, setOpen] = React.useState(false);
   
   
    const ShowConfirmDelete = async (val) => {
        console.log(val.target)
        setIdToSuppr(val.target.id);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleButtonColumn = () => {
        console.log('clicked')
    };
    
    const suppression = async (e)=> {
        helpers
            .supprGroupe({id: idtosuppr, langue: i18n.language })
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                    getGroupes().catch(console.error);
                    setOpen(false);
                }
            })
    };


    useEffect(()=>{
        const filteredTables = tables && tables.filter(
            item => {
                return (
                    item.nom !== null && item.nom
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                    item.adresse !== null && item.adresse
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                    item.commentaire !== null && item.commentaire
                        .toLowerCase()
                        .includes(searchField.toLowerCase())
                );
            }
        );
        if(searchField == '')
            getGroupes().catch(console.error);
        if(filteredTables !== undefined)
       setTables(filteredTables);
    },[searchField]);

    function rechercheindb(){
        if(searchField != ''){
            return;
        }
        return;
    }

    function newGroupes(e) {
        e.preventDefault();
        setArrayItemgroupe([]);
        setshowmodal(true);
    }

    const getGroupes = async () => {
        helpers
            .getAllGroupes()
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    setTables( r.data);
                }
            })
    };

    const getinvoiceaccounttodropdown = async () => {
        helpers
            .getinvoiceaccount()
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    setIdentificationgroupes(r.data);
                }
            })
    };



    useEffect(()=>{
        setLoading(true);
        getGroupes().catch(console.error);
        getinvoiceaccounttodropdown().catch(console.error)

    },[]);


    const savenewGroupe =(e)=> {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const nom = data.get('nom');
        const commentaire = data.get('commentaire');

        if (nom === "") {
            setOpenToast({
                isOpenToast: true,
                txt: Outsidecomponenti18n(`groupe.veuillezinsererlenomdugroupe`)
            });
        }
        setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);

        if (arrayitemgroupe.length === 0) {
            setOpenToast({
                isOpenToast: true,
                txt: Outsidecomponenti18n(`groupe.veuillezajouteraumoinsungroupe`)
            });
        }
        setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);

        setLoadingSave(true);
        let dataX = {
            nom: nom,
            identification: arrayitemgroupe,
            commentaire: commentaire,
            langue: i18n.language
        };

        helpers
            .groupesave(dataX)
            .then(async r => {
                setLoadingSave(false);
                if (r.status === 200) {
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message
                    });
                    setshowmodal(false);
                    getGroupes().catch(console.error);
                } else {
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message,
                    });
                }
            })
            .catch(e => {
                setLoadingSave(false);
            });
    };
    const setGroupeOnchange =(e)=> {
    }


    
    const modifGroupe =(e)=> {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
       
        if (nommodif === "") {
            setOpenToast({
                isOpenToast: true,
                txt: Outsidecomponenti18n(`groupe.lenomnepourraitetrepasvide`)
            });
        }
            setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);

            if (arrayitemgroupe === "") {
                setOpenToast({
                    isOpenToast: true,
                    txt: Outsidecomponenti18n(`groupe.veuillezchoisiridgroupe`)
                });
            }
                setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);



        setLoadingSave(true);
        let dataX = {
            id:idmodif,
            nom: nommodif,
            identification: arrayitemgroupe,
            commentaire: commentairemodif,
            langue:i18n.language
        };

        helpers
            .groupemodification(dataX)
            .then(async r => {
                setLoadingSave(false);
                if (r.status === 200) {
                    await getGroupes().catch(()=>null);
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message
                    });
                    setshowmodalmodif(false);
                } else {
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message,
                    });
                }
            })
            .catch(e => {
                setLoadingSave(false);
            });
    };
    const selectProps = { indeterminate: isIndeterminate => isIndeterminate };

    const handleClickChip = () => {
    };

    function handleDeleteChip (a,id) {
        const arrmp = [...arrayitemgroupe];
        const newarrmp = arrmp.filter(
            item => {
                return (
                    item != id
                )
            });
        setArrayItemgroupe(newarrmp)
    };


  return (
      <LoadingOverlay
          active={isLoading}
          spinner
          text={Outsidecomponenti18n(`groupe.chargement`)}
      >
      <div className="content-wrapper">
        <div className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0 text-dark">{Outsidecomponenti18n(`groupe.groupes`)}</h1>
                    </div>{/* /.col */}
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item">
                                    {Outsidecomponenti18n(`groupe.groupe`)}
                            </li>
                            <li className="breadcrumb-item active">
                                <Link to="/dashboard"  className="link">
                                myGlobalNet
                                </Link>
                            </li>
                        </ol>
                    </div>{/* /.col */}
                </div>{/* /.row */}
                <button
                    onClick={(e)=>newGroupes(e)}
                    className={"btn btn-success px-4"}><i className="fas fa-plus-circle mr-2" />{Outsidecomponenti18n(`groupe.ajout`)}</button>
            </div>{/* /.container-fluid */}
        </div>

          {/*le datatable*/}
          <div className="card">
              <div className="card-body">
                  <Paper
                      component="form"
                      sx={{ p: '2px 2px', display: 'flex', alignItems: 'center', width: 200 }}
                  >
                      <InputBase
                          sx={{ ml: 1, flex: 1 }}
                          placeholder="Rechercher"
                          inputProps={{ 'aria-label': 'Rechercher' }}
                          onChange={(e)=>{
                              e.preventDefault();
                              setSearchField(e.target.value);
                          }}
                          value={searchField}
                      />
                      <IconButton  onClick={() => {
                          rechercheindb()
                      }} type="button" sx={{ p: '5px' }} aria-label="search">
                          <SearchIcon />
                      </IconButton>
                  </Paper>
                  <DataTable
                      pagination
                      // selectableRowsComponent={Checkbox}
                      selectableRowsComponentProps={selectProps}
                      // sortIcon={sortIcon}
                      dense
                      //{...props}
                      //title="Administration"
                      //theme={'dark'}
                      //columns={columns}
                      columns={columns(handleButtonColumn, ShowConfirmDelete)}
                      data={tables}
                      // selectableRows
                      striped
                      highlightOnHover
                      persistTableHead
                      fixedHeader={false}
                      progressPending={false}
                      noDataComponent={Outsidecomponenti18n(`groupe.aucunedonneaafficher`)}
                      onRowClicked={async(row, event) => {
                          setidmodif(row?.id);
                          setnommodif(row?.nom);
                          setcommentairemodif(row?.commentaire);
                          setshowmodalmodif(true);
                          let newarr = [];
                          if(row.identificationgroupes.length>0)
                          for await( let it of row.identificationgroupes){
                            await newarr.push(it.identification )
                          }
                          if(newarr.length>0){
                            setArrayItemgroupe(newarr);
                            setGroupe("");
                          }                            
                      }}
                      paginationComponentOptions={{ rowsPerPageText: Outsidecomponenti18n(`groupe.ligneparpage`), rangeSeparatorText: Outsidecomponenti18n(`groupe.du`), noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: Outsidecomponenti18n(`groupe.tout`) }}
                  />
              </div>
              {/*fin body*/}
          </div>

          <Modal
              open={isshowmodal}
              onClose={()=>setshowmodal(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              style={{overflow: 'scroll' }}

          >
              <div className="modal-dialog ">
                  <div className="modal-content">
                      <div className="modal-header">
                          <h4 className="modal-title">{Outsidecomponenti18n(`groupe.nouveaugroupe`)}</h4>
                          <button onClick={()=>setshowmodal(false)} type="button" className="close" data-dismiss="modal" aria-label={Outsidecomponenti18n(`groupe.fermer`)}>
                              <span aria-hidden="true">&times;</span>
                          </button>
                      </div>
                      <Box component="form" onSubmit={savenewGroupe} sx={{ mt: 1 }}
                           Validate
                           autoComplete="on"   >

                      <div   className="modal-body">
                              <TextField
                                  margin="normal"
                                  required
                                  fullWidth
                                  id="nom"
                                  label={Outsidecomponenti18n(`groupe.nom`)}
                                  name="nom"
                                  autoComplete="nom"
                                  autoFocus
                              />
                                    <div style={{ justifyContent:'flex-end', marginTop:10, marginRight:5}} className="row">
                                        {arrayitemgroupe.length>0 && arrayitemgroupe.map((item, i)=>{
                                          return <Chip
                                                label={item}
                                                onClick={handleClickChip}
                                                onDelete={(a)=>handleDeleteChip(a,item)}
                                            />
                                        })}
                                    </div>
                                    <div style={{ justifyContent:'flex-end', marginTop:10, marginRight: 10}} className="row">
                                        <Autocomplete
                                            margin="normal"
                                            disablePortal
                                            id="combo-box-demo1"
                                            value={{label:groupe}}
                                            options={
                                                identificationgroupes.length > 0 ?
                                                identificationgroupes
                                                    :
                                                    []
                                            }
                                            onChange={async(value0, value1)=>{
                                                let arrayitemgroup = [...arrayitemgroupe];
                                                    await arrayitemgroup.push(value1.label);
                                                    setArrayItemgroupe(arrayitemgroup);
                                                setGroupe(value1.label); 
                                                setGroupeOnchange(value1)
                                            }}
                                            sx={{ width: 300 }}
                                            renderInput={(params) => <TextField {...params}  value={groupe} onChange={(value)=>{setGroupe(value.target.value); }} label="InvoiceAccount" />}
                                        />
                                    </div>
                          <TextField
                              margin="normal"
                          //    required
                              fullWidth
                              id="commentaire"
                              label={Outsidecomponenti18n(`groupe.commentaire`)}
                              name="commentaire"
                              autoComplete="commentaire"
                              autoFocus
                          />
                      </div>
                      <div className="modal-footer d-flex flex-row justify-content-between">
                          <button onClick={()=>setshowmodal(false)} type="button" className="btn btn-default" data-dismiss="modal">{Outsidecomponenti18n(`groupe.fermer`)}</button>
                          <Button
                              onPress = {savenewGroupe}
                              type="submit"
                              // fullWidth
                              variant="contained"
                              // sx={{ mt: 3, mb: 2 }}
                              startIcon={
                                  isLoadingSave ? <img width={15} src={require("../../assets/loading-load.gif").default} alt="wait..." />
                                 : <SaveIcon fontSize="inherit" />
                              }
                          >
                              {Outsidecomponenti18n(`groupe.enregistrer`)}
                          </Button>
                      </div>
                      </Box>

                  </div>
              </div>
          </Modal>
          <Modal
              open={isshowmodalmodif}
              onClose={()=>setshowmodalmodif(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              style={{overflow: 'scroll' }}

          >
              <div className="modal-dialog ">
                  <div className="modal-content">
                      <div className="modal-header">
                          <h4 className="modal-title">{Outsidecomponenti18n(`groupe.modificationgroupe`)}</h4>
                          <button onClick={()=>setshowmodalmodif(false)} type="button" className="close" data-dismiss="modal" aria-label={Outsidecomponenti18n(`groupe.fermer`)}>
                              <span aria-hidden="true">&times;</span>
                          </button>
                      </div>

                      <Box component="form" onSubmit={modifGroupe} sx={{ mt: 1 }}
                           Validate
                           autoComplete="on"   >

                          <div   className="modal-body">

                              <TextField
                                  margin="normal"
                                  required
                                  fullWidth
                                  id="nom"
                                  label={Outsidecomponenti18n(`groupe.nom`)}
                                  name="nom"
                                  autoComplete="nom"
                                  autoFocus
                                  value={nommodif}
                                  onChange={(e)=>setnommodif(e.target.value)}
                              />
                                <div style={{ justifyContent:'flex-end', marginTop:10, marginRight:5}} className="row">
                                        {arrayitemgroupe.length>0 && arrayitemgroupe.map((item, i)=>{
                                                return <Chip
                                                    label={item}
                                                    onClick={handleClickChip}
                                                    onDelete={(a)=>handleDeleteChip(a,item)}   />
                                        })}
                                    </div>
                                <div style={{ justifyContent:'flex-end', marginTop:10, marginRight: 10}} className="row">
                                        <Autocomplete
                                            margin="normal"
                                            disablePortal
                                            id="combo-box-demo1"
                                            value={{label:groupe}}
                                            options={
                                                identificationgroupes.length > 0 ?
                                                identificationgroupes
                                                    :
                                                    []
                                            }
                                    
                                            onChange={async (value0, value1)=>{
                                                if(value1 != null && value1 != ''){
                                                    setGroupe(value1.label); 
                                                    setGroupeOnchange(value1)
                                                    let arrayitemgroup = [...arrayitemgroupe];
                                                    await arrayitemgroup.push(value1.label);
                                                    setArrayItemgroupe(arrayitemgroup);
                                                }
                                            }}
                                            sx={{ width: 300 }}                                
                                          
                                            renderInput={(params) => <TextField {...params} value={groupe} onChange={(value)=>{setGroupe(value.target.value); }} label="InvoiceAccount" />}
                                        />
                                    </div>

                             
                       
                              <TextField
                                  margin="normal"
                                  //    required
                                  fullWidth
                                  id="commentairemodif"
                                  label={Outsidecomponenti18n(`groupe.commentaire`)}
                                  name="commentairemodif"
                                //  autoComplete="commentaire"
                                  value={commentairemodif}
                                  onChange={(e)=>setcommentairemodif(e.target.value)}
                              />
                          </div>
                          <div className="modal-footer d-flex flex-row justify-content-between">
                              <button onClick={()=>setshowmodalmodif(false)} type="button" className="btn btn-default" data-dismiss="modal">{Outsidecomponenti18n(`groupe.fermer`)}</button>
                              <Button
                                  onPress = {modifGroupe}
                                  type="submit"
                                  // fullWidth
                                  variant="contained"
                                  // sx={{ mt: 3, mb: 2 }}
                                  startIcon={
                                      isLoadingSave ? <img width={15} src={require("../../assets/loading-load.gif").default} alt="wait..." />
                                          : <SaveIcon fontSize="inherit" />
                                  }
                              >
                                  {Outsidecomponenti18n(`groupe.enregistrer`)}
                              </Button>
                          </div>
                      </Box>

                  </div>
              </div>
          </Modal>
          <Snackbar
              open={openToast.isOpenToast}
              autoHideDuration={6000}
              //onClose={()=>setOpenToast({...openToast, isOpenToast:false})}
              message={openToast.txt}
              action={<React.Fragment>
                  <IconButton
                      size="small"
                      aria-label={Outsidecomponenti18n(`groupe.fermer`)}
                      color="inherit"
                      onClick={(event, reason) => {
                          if (reason === 'clickaway') {
                              return;
                          }
                          setOpenToast(false);
                      }}
                  >
                      <CloseIcon fontSize="small" />
                  </IconButton>
              </React.Fragment>}
          />
      </div>
          <Dialog
              open={open}
              onClose={handleClose}
              // PaperComponent={PaperComponent}
              // aria-labelledby="draggable-dialog-title"
              //scroll={'body'}
          >
              <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                  {Outsidecomponenti18n(`groupe.suppressiongroupe`)}
              </DialogTitle>
              <DialogContent>
                  <DialogContentText>
                      {Outsidecomponenti18n(`groupe.voulezvousvraimentsuppr`)}
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button  onClick={handleClose}>
                      {Outsidecomponenti18n(`groupe.annuler`)}
                  </Button>
                  <Button onClick={suppression}>{Outsidecomponenti18n(`groupe.ok`)}</Button>
              </DialogActions>
          </Dialog>
      </LoadingOverlay>
  )
};
