import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from "@mui/material/Snackbar/Snackbar";
import LoadingOverlay from "react-loading-overlay";
import DataTable from 'react-data-table-component';
import 'react-data-table-component-extensions/dist/index.css';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import { useLocation, useNavigate } from "react-router-dom"
import Toll from '@mui/icons-material/Toll';
import ls from 'local-storage'
import Button from "@mui/material/Button";
let lacommande = ls.get('commande.groupe');
import { useTranslation } from "react-i18next";
const itemdesadv =  JSON.parse( ls.get('itemdesadv'));

const columns = ((clickHandler, onClickButtonlist) => [
    
    {
        name: 'Z',
        selector: row => row.WMSShipmentId,
        sortable:true,
        wrap:true,
        style:{fontSize:16},
    },
    {
        name: 'Commande n°',
        wrap:true,
        cell: (row) => <Link to="/dashboard/commande"  className="link">
                    <div  
                 style={{ }}>{itemdesadv?.SONumber + '/' + itemdesadv?.MessageNumber}</div>
            </Link> },

    {
        name: 'PackingSlipIds',
        selector: row => row?.PackingSlipIds,
        wrap:true,
        sortable:true,
        hover:'zeze',
        cell: (row) => <Link to="/dashboard/commande/desadv"  className="link">
        <div  
     style={{ }}>{row.PackingSlipIds}</div>
</Link> 
    },
    {
        name: 'conteneur',
        selector: row => row?.ContainerId1,
        wrap:true,
        sortable:true,
        cell: (row) => <div 
        style={{ }}>{row?.ContainerId1}</div>
    },
    {
        name: 'Destination',
        selector: row => row?.Destination,
        wrap:true,
        sortable:true,
        cell: (row) => <div 
        style={{ }}><span style={{color:'purple'}}>{row?.Destination}</span> {row?.DestinationName}</div>
    },
    {
        name: 'Endpoint',
        selector: row => row.best_fichier_z.SourceEndpoint,
        wrap:true,
        sortable:true,
        cell: (row) => <div 
        style={{ }}>{row.best_fichier_z.SourceEndpoint + '->' + row?.best_fichier_z?.DestinationEndpoint}</div>
    },
    {
        name: 'CountryRegion',
        selector: row => row.best_fichier_z.CountryRegionId,
        wrap:true,
        sortable:true,
        cell: (row) => <div 
        style={{ }}>{row.best_fichier_z.CountryRegionId}</div>
    },
    {
        name: 'NoPackages',
        selector: row => row.best_fichier_z.NoPackages,
        wrap:true,
        sortable:true,
        cell: (row) => <div 
        style={{ }}>{row.best_fichier_z.NoPackages}</div>
    },
    {
        name: 'Weight',
        selector: row => row.best_fichier_z.Weight,
        wrap:true,
        sortable:true,
        cell: (row) => <div 
        style={{ }}>{row.best_fichier_z.Weight}</div>
    },
    {
        name: 'Colis',
        wrap:true,
        button: true,
        cell: (row) =>  <Link to="/dashboard/commande/desadv/z/colis"  className="link">
                <div
                style={{ }}><span style={{paddingTop:4, paddingBottom:4, paddingLeft:6, paddingRight:6, backgroundColor:'#75ff42', borderRadius:10}}>{row?.ShipmentCollis.length}</span>
                <Button className={"btn btn-success px-1"}
                onClick={async function (e){
                    await ls.set('ShipmentCollis', JSON.stringify(row.ShipmentCollis));
                }} id={row.id} style={{fontSize:11}}>Colis</Button></div>
                </Link>,

    },
    {
        name: 'WMSOrderTrans',
        wrap:true,
        button: true,
        cell: (row) =>  <Link to="/dashboard/commande/desadv/z/WMSOrderTrans"  className="link">
                <div
                style={{ }}><span style={{paddingTop:4, paddingBottom:4, paddingLeft:6, paddingRight:6, backgroundColor:'#75ff42', borderRadius:10}}>{row?.WMSOrderTrans.length}</span>
                <Button className={"btn btn-success px-1"}
                onClick={async function (e){
                    await ls.set('WMSOrderTrans', JSON.stringify(row.WMSOrderTrans));
                }} id={row.id} style={{fontSize:11}}>WMSOrderTrans</Button></div>
                </Link>,

    },
    {
        name: 'Groupe',
        selector: row => {
            const parsegroupe = JSON.parse(lacommande);
            if(parsegroupe && parsegroupe.nom)
            return parsegroupe.nom;
        },
        sortable:true,
        wrap:true,
        style:{fontSize:16},
    },
    // {
    //     name: '',
    //     wrap:true,
    //     button: true,
    //     cell: (row) => <div style={{ }}><div  onClick={onClickButtonlist} id={row.id} style={{fontSize:11, color: 'red'}}>*<Toll />*</div></div>,}
]);


export const Centredesadv_z = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { i18n } = useTranslation();

    const [isshowmodal, setshowmodal] = useState(false);
    const [openSnack, setOpenSnack] = useState({
        isOpenNomError:false,
        txt:''});
    const [openToast, setOpenToast] = useState({isOpenToast:false, txt:''});
    const [tables, setTables] = React.useState([]);
    const [isLoading, setLoading] = React.useState(false);
    const [searchField, setSearchField] = useState("");
    const [detailToShow, setDetailToShow] = useState(null);
    
    const goToFacture = (val) => {
        const from =  `/dashboard/facture`;
        navigate(from, { replace: true })
    };
    
    const onClickButtonlist = (val) => {
      
        console.log("ok")
    };
    
    

    useEffect(()=>{
        const filteredTables = tables && tables.filter(
            item => {
                return (
                    item.nom !== null && item.nom
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                    item.adresse !== null && item.adresse
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                    item.commentaire !== null && item.commentaire
                        .toLowerCase()
                        .includes(searchField.toLowerCase())
                );
            }
        );
        if(searchField == '')
        get_fichier_z().catch(console.error);
        if(filteredTables !== undefined)
       setTables(filteredTables);
    },[searchField]);

    function rechercheindb(){
        if(searchField != ''){
            return;
        }
        return;
    }


    const get_fichier_z = async () => {
        const fichier_z = await JSON.parse(await ls.get('z'));
        console.log('lez', fichier_z)
        setTables(fichier_z);
        setLoading(false);
    };

    useEffect(()=>{
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 10000);
        get_fichier_z().catch(console.error);
    },[]);


    const selectProps = { indeterminate: isIndeterminate => isIndeterminate };


  return (
      <LoadingOverlay
          active={isLoading}
          spinner
          text='Chargement...'
      >
      <div className="content-wrapper">
        <div className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0 text-dark">Fichier Z</h1>
                    </div>{/* /.col */}
                    <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                <Link to="/dashboard/commande"  className="link">
                    {"<     Retour aux commandes"}
                </Link>
                </ol>
                </div>
                    {/* <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item">
                            
                                    Z
                            </li>
                        <li className="breadcrumb-item">
                        <Link to="/dashboard/commande/desadv"  className="link">
                                    desadv
                                </Link>
                            </li>
                           
                            <li className="breadcrumb-item">
                            <Link to="/dashboard/commande"  className="link">
                                    commande
                                </Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <Link to="/dashboard"  className="link">
                                myGlobalNet
                                </Link>
                            </li>
                        </ol>
                    </div> */}
                </div>{/* /.row */}
            </div>{/* /.container-fluid */}
        </div>

          {/*le datatable*/}
          <div className="card">
              <div className="card-body">
                  <Paper
                      component="form"
                      sx={{ p: '2px 2px', display: 'flex', alignItems: 'center', width: 200 }}
                  >
                      <InputBase
                          sx={{ ml: 1, flex: 1 }}
                          placeholder="Rechercher"
                          inputProps={{ 'aria-label': 'Rechercher' }}
                          onChange={(e)=>{
                              e.preventDefault();
                              setSearchField(e.target.value);
                          }}
                          value={searchField}
                      />
                      <IconButton  onClick={() => {
                          rechercheindb()
                      }} type="button" sx={{ p: '5px' }} aria-label="search">
                          <SearchIcon />
                      </IconButton>
                  </Paper>


                  <DataTable
                      pagination
                      selectableRowsComponentProps={selectProps}
                      dense                
                      columns={columns(goToFacture, onClickButtonlist)}
                      data={tables}
                      striped
                      highlightOnHover
                      persistTableHead
                      fixedHeader={false}
                      progressPending={false}
                      noDataComponent={"Aucune données à afficher"}
                      onRowClicked={(row, event) => {console.log('row clicked', row);
                      setDetailToShow(row);
                       
                       //   setshowmodal(true);
                      }}
                      paginationComponentOptions={{ rowsPerPageText: 'Lignes par page:', rangeSeparatorText: 'of', noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All' }}
                  />
              </div>
          </div>

          
          <Modal
              open={isshowmodal}
              onClose={()=>setshowmodal(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              style={{overflow: 'scroll' }}

          >
              <div className="modal-dialog ">
                  <div className="modal-content">
                      <div className="modal-header">
                          <h4 className="modal-title">Détails du facture</h4>
                          <button onClick={()=>setshowmodal(false)} type="button" className="close" data-dismiss="modal" aria-label="Fermer">
                              <span aria-hidden="true">&times;</span>
                          </button>
                      </div>
                      <div   className="modal-body">

                              
                         
                      </div>
                  </div>
              </div>
          </Modal>
          <Snackbar
              open={openToast.isOpenToast}
              autoHideDuration={6000}
              //onClose={()=>setOpenToast({...openToast, isOpenToast:false})}
              message={openToast.txt}
              action={<React.Fragment>
                  <IconButton
                      size="small"
                      aria-label="fermer"
                      color="inherit"
                      onClick={(event, reason) => {
                          if (reason === 'clickaway') {
                              return;
                          }
                          setOpenToast(false);
                      }}
                  >
                      <CloseIcon fontSize="small" />
                  </IconButton>
              </React.Fragment>}
          />
      </div>
          
      </LoadingOverlay>
  )
};
