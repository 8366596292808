import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField/TextField";
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import Button from "@mui/material/Button";
import {Alert} from "../Authentication/index";
import SaveIcon from '@mui/icons-material/Save';
import FormControlLabel from '@mui/material/FormControlLabel';
import {helpers} from "../../services/api/helpers";
import ls from "local-storage";
import Snackbar from "@mui/material/Snackbar/Snackbar";
import {ThemeProvider} from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import LoadingOverlay from "react-loading-overlay";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import Checkbox from "@mui/material/Checkbox/Checkbox";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import {  useNavigate    } from "react-router-dom"
import {Outsidecomponenti18n} from "../shared/Outsidecomponenti18n";
import { useTranslation } from "react-i18next";

let isAdmin = ls.get('xxxx');

const validateEmail = (email) => {
    return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};



// let columns =  [
let    columns = ((clickHandler) => [

        {
            name: Outsidecomponenti18n(`message.email`),
            selector: row => row.email,
        },
        {
            name:  Outsidecomponenti18n(`message.message`),
            selector: row => row.message,
        },
        {
            name:  Outsidecomponenti18n(`message.repondu`),
            wrap:true,
            button: true,
            cell: (row) => <div style={{ }}>
                
                {row.isRepondu ? <i className="fas fa-check" style={{color: "#74b842" , fontSize:17}}></i>
                :
                Outsidecomponenti18n(`message.non`)   
            }
                </div>,
        },
        
    ]);
    
export const Centre = () => {
    const navigate = useNavigate();
    const { i18n } = useTranslation();
    const { t } = useTranslation();

    const [openModalSuppression, setopenModalSuppression] = useState(false);
    const [selected, setSelected] = useState({ allSelected:false, selectedCount:0, selectedRows:[] });
    const [isshowmodal, setshowmodal] = useState(false);
    const [isLoadingSave, setLoadingSave] = useState(false);

    const [openToast, setOpenToast] = useState({isOpenToast:false, txt:''});
    const [tables, setTables] = React.useState({columns:columns});
    const [isLoading, setLoading] = React.useState(false);
    const [searchField, setSearchField] = useState("");
    const [reponsemessage, setReponsemessage] = useState("");
    const [reponsemessagecommercial, setReponsemessagecommercial] = useState("");
    const [email, setEmail] = useState("");
    const [objet, setobjet] = useState("");
    const [id, setId] = useState("");





        useEffect(()=>{
        const filteredPersons = tables.data && tables.data.filter(
            item => {
                return (
                    item
                        .email
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                    item
                        .message
                        .toLowerCase()
                        .includes(searchField.toLowerCase())
                );
            }
        );
        if(searchField == ''){
            getMessages().catch(console.error);
        }
        setTables({...tables, data: filteredPersons});
    },[searchField]);

    function rechercheindb(){

        if(searchField != ''){
            return;
        }
        return;

    }




    const getMessages = async () => {
        if(isAdmin === "AAAA")
        helpers
            .getmessages()
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    setTables({...tables, data: r.data});
                }
            })
            else
            setLoading(false);

    };

    useEffect(()=>{
        setLoading(true);
        getMessages().catch(console.error);

    },[]);


    
    const funcmessageverscommercial =(e)=> {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const objet = data.get('objetverscommercial');
        if(objet.trim() == ''){
            setOpenToast({
                isOpenToast: true,
                txt: Outsidecomponenti18n(`message.lobjetestvide`),
            });
            return;
        }
        setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
        setLoadingSave(true);
        let dataX = {
            objet,
            message: reponsemessagecommercial,
            langue: i18n.language
        };
        helpers
        .sendtocommercial(dataX)
        .then(async r => {
            setLoadingSave(false);
            if (r.status === 200) {
                getMessages().catch(console.error);
                setOpenToast({
                    isOpenToast: true,
                    txt: "Message envoyé avec succès.",
                });
                setReponsemessagecommercial("")
                setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
           
            }else{
                setOpenToast({
                    isOpenToast: true,
                    txt:r.message,
                });
                setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
               
            }
        })

    }

    const repondremessage =(e)=> {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const object = data.get('objet');
       
        if(reponsemessage.trim() == ''){
            setOpenToast({
                isOpenToast: true,
                txt: Outsidecomponenti18n(`message.votremessageestvide`),
            });
            return;
        }
        setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);

        setLoadingSave(true);
        let dataX = {
            message: reponsemessage,
            objet,
            email,
            id
        };
    
        helpers
            .repondremessage(dataX)
            .then(async r => {
                setLoadingSave(false);
                if (r.status === 200) {
                    getMessages().catch(console.error);
                    setOpenToast({
                        isOpenToast: true,
                        txt: Outsidecomponenti18n(`message.messagebienrepondu`),
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                    setshowmodal(false);

                } else {
                    setOpenToast({
                        isOpenToast: true,
                        txt: Outsidecomponenti18n(`message.erreurenvoimessage`),
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                }
            })
            .catch(e => {
                setLoadingSave(false);
            });
    };
    const selectProps = { indeterminate: isIndeterminate => isIndeterminate };
    


    const suppression = async (e) => {
        e.preventDefault();
        if(selected.selectedCount==0)
            return;
        setLoading(true);
        helpers
            .supprMessages(selected.selectedRows)
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    getMessages().catch(console.error);
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message,
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                    setopenModalSuppression(false);
                }else{
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message,
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                    setopenModalSuppression(false)
                }
            }).catch((r)=>{
                setOpenToast({
            isOpenToast: true,
            txt: r.message,
        });
            setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
        })
    };

  return (
      <LoadingOverlay
          active={isLoading}
          spinner
          text={Outsidecomponenti18n(`message.chargement`)}
      >
      <div className="content-wrapper">
        <div className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                      {isAdmin === "AAAA" ?
                       <h1 className="m-0 text-dark">{Outsidecomponenti18n(`message.message`)}</h1>
                       :
                       <h1 className="m-0 text-dark">{Outsidecomponenti18n(`message.messageverscommercial`)}</h1>

                    }
                    </div>{/* /.col */}
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item">
                            {Outsidecomponenti18n(`message.message`)}
                            </li>
                            <li className="breadcrumb-item active">
                                <Link to="/dashboard"  className="link">
                                myGlobalNet
                                </Link>
                            </li>
                        </ol>
                    </div>{/* /.col */}
                </div>{/* /.row */}
            </div>{/* /.container-fluid */}
        </div>

          {/*le datatable*/}
          <div className="card">
              {  isAdmin !== "AAAA" &&  <div className="card-body">
              <Box style={{width:"50%"}} component="form" onSubmit={funcmessageverscommercial} sx={{ mt: 1 }}
                           Validate
                           autoComplete="on"   >
                      <div   className="modal-body">
                          <TextField
                              margin="normal"
                              required
                              fullWidth
                              id="objetverscommercial"
                              label={Outsidecomponenti18n(`message.objet`)}
                              name="objetverscommercial"
                              autoFocus
                          />
                        <div style={{fontSize:10}} className="">
                            {reponsemessagecommercial.length  + "/" +  500}
                      </div>
                        <textArea 
                          onChange={ (event) => {
                            if(reponsemessagecommercial.length <501)
                            setReponsemessagecommercial(event.target.value)
                        }}
                        name="reponsemessagecommercial"
                        style={{width:'100%', height:150}} className="">
    
                      </textArea>
                          

                      </div>
                      <div className="modal-footer d-flex flex-row justify-content-between">

                          <Button
                              onPress = {funcmessageverscommercial}
                              type="submit"
                              // fullWidth
                              variant="contained"
                              // sx={{ mt: 3, mb: 2 }}
                              startIcon={
                                  isLoadingSave ? <img width={15} src={require("../../assets/loading-load.gif").default} alt={Outsidecomponenti18n(`message.attendre`)} />
                                 : <SaveIcon fontSize="inherit" />
                              }
                          >
                              {Outsidecomponenti18n(`message.envoyer`)}
                          </Button>
                      </div>
                      </Box>
                </div>}
              {  isAdmin === "AAAA" &&  <div className="card-body">
             <div className="d-flex flex-row justify-content-between">
                       <button
                          onClick={(e)=>setopenModalSuppression(true)}
                          className={"btn btn-danger px-4"}><i className="fas fa-trash mr-2" />{Outsidecomponenti18n(`message.supprimer`)}</button>
                  <Paper
                      component="form"
                      sx={{ p: '2px 2px', display: 'flex', alignItems: 'center', width: 200 }}
                  >
                      <InputBase
                          sx={{ ml: 1, flex: 1 }}
                          placeholder= {Outsidecomponenti18n(`message.rechercher`)}
                          inputProps={{ 'aria-label': 'Rechercher' }}
                          onChange={(e)=>{
                              e.preventDefault();
                              setSearchField(e.target.value);
                          }}
                          value={searchField}
                      />
                      <IconButton  onClick={() => {
                          rechercheindb()
                      }} type="button" sx={{ p: '5px' }} aria-label="search">
                          <SearchIcon />
                      </IconButton>
                  </Paper>
                  </div>
                   <DataTableExtensions
                      {...tables}
                      filter={false}
                     print={false}
                     export={false}
                     filterPlaceholder={'Rechercher'}
                  >
                  <DataTable
                      pagination
                      selectableRowsComponentProps={selectProps}
                      dense
                      selectableRows
                      striped
                      highlightOnHover
                      persistTableHead
                      fixedHeader={false}
                      progressPending={false}
                      noDataComponent={Outsidecomponenti18n(`message.aucunedonneafficher`)}
                      onRowClicked={(row, event) => {
                          setEmail(row.email);
                          setId(row.id)
                          setshowmodal(true)
                      }}
                      onSelectedRowsChange={({ allSelected, selectedCount, selectedRows }) => {
                          const se = { allSelected:allSelected, selectedCount:selectedCount, selectedRows:selectedRows };
                            setSelected(se)
                      }}
                      paginationComponentOptions={{ rowsPerPageText: Outsidecomponenti18n(`message.ligneparpage`), rangeSeparatorText: Outsidecomponenti18n(`message.of`), noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: Outsidecomponenti18n(`message.all`) }}
                  />
                  </DataTableExtensions>
                
              </div>}
              {/*fin body*/}
          </div>

          <Modal
              open={isshowmodal}
              onClose={()=>setshowmodal(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              style={{overflow: 'scroll' }}

          >
              <div className="modal-dialog ">
                  <div className="modal-content">
                      <div className="modal-header">
                          <h4 className="modal-title">{Outsidecomponenti18n(`message.repondre`)} : </h4>
                          <button onClick={()=>setshowmodal(false)} type="button" className="close" data-dismiss="modal" aria-label={Outsidecomponenti18n(`message.fermer`)}>
                              <span aria-hidden="true">&times;</span>
                          </button>
                      </div>
                      <Box component="form" onSubmit={repondremessage} sx={{ mt: 1 }}
                           Validate
                           autoComplete="on"   >
                      <div   className="modal-body">
                          

                          <TextField
                              margin="normal"
                              required
                              fullWidth
                              id="email"
                              label={Outsidecomponenti18n(`message.email`)}
                              name="email"
                              autoComplete="email"
                              disabled
                              value={email}
                              type={'email'}
                          />
                         
                          <TextField
                              margin="normal"
                              required
                              fullWidth
                              id="object"
                              label={Outsidecomponenti18n(`message.objet`)}
                              name="objet"
                              onChange={async (event) => {
                                let aa = event.target.value;
                                setobjet(aa)
                            }}
                              autoFocus
                          />
                        <div style={{fontSize:10}} className="">
                            {reponsemessage.length  + "/" +  500}
                      </div>
                        <textArea 
                          onChange={ (event) => {
                            if(reponsemessage.length <501)
                            setReponsemessage(event.target.value)
                        }}
                        name="reponsemessage"
                        style={{width:'100%', height:150}} className="">
    
                      </textArea>
                          

                      </div>
                      <div className="modal-footer d-flex flex-row justify-content-between">
                          <button onClick={()=>setshowmodal(false)} type="button" className="btn btn-default" data-dismiss="modal">{Outsidecomponenti18n(`message.fermer`)}</button>
                          <Button
                              onPress = {repondremessage}
                              type="submit"
                              // fullWidth
                              variant="contained"
                              // sx={{ mt: 3, mb: 2 }}
                              startIcon={
                                  isLoadingSave ? <img width={15} src={require("../../assets/loading-load.gif").default} alt={Outsidecomponenti18n(`message.attendre`)} />
                                 : <SaveIcon fontSize="inherit" />
                              }
                          >
                              {Outsidecomponenti18n(`message.envoyer`)}
                          </Button>
                      </div>
                      </Box>

                  </div>
              </div>


          </Modal>

          

          <Dialog
              open={openModalSuppression}
              keepMounted
              onClose={()=>setopenModalSuppression(false)}
              aria-describedby="alert-dialog-slide-description"
          >
              <DialogTitle>{"Voulez-vous vraiment supprimer?"}</DialogTitle>
              <DialogActions>
                  <Button onClick={()=>setopenModalSuppression(false)}>{Outsidecomponenti18n(`message.annuler`)}</Button>
                  <Button onClick={suppression}>{Outsidecomponenti18n(`message.ok`)}</Button>
              </DialogActions>
          </Dialog>
          <Snackbar
              open={openToast.isOpenToast}
              autoHideDuration={6000}
              message={openToast.txt}
              action={<React.Fragment>
                  <IconButton
                      size="small"
                      aria-label="close"
                      color="inherit"
                      onClick={(event, reason) => {
                          if (reason === 'clickaway') {
                              return;
                          }
                          setOpenToast(false);
                      }}
                  >
                      <CloseIcon fontSize="small" />
                  </IconButton>
              </React.Fragment>}
          />
      </div>
      </LoadingOverlay>
  )
};
