import { baseUrl } from "../constantes";
import ls from "local-storage";
//import { useSelector } from "react-redux";

export const helpers = {
  sendtocommercial: async data => {
    const userToken_ = await await ls.get('userToken');
    const response = await (
      await fetch(baseUrl + "/commercial/message", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + userToken_

        },
        body: JSON.stringify(data)
      })
    ).json();
    return response;
  },
  groupemodification: async data => {
    const userToken_ = await await ls.get('userToken');
    const response = await (
      await fetch(baseUrl + "/groupe/edit", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + userToken_

        },
        body: JSON.stringify(data)
      })
    ).json();
    return response;
  },
  groupesave: async data => {
    const userToken_ = await await ls.get('userToken');
    const response = await (
      await fetch(baseUrl + "/groupe/save", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + userToken_

        },
        body: JSON.stringify(data)
      })
    ).json();
    return response;
  },
  getArticlespluscommandetoken: async () => {
    const userToken_ = await await ls.get('userToken');
    const response = await (
      await fetch(baseUrl + "/articles4token/get", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + userToken_
        }
      })
    ).json();
    return response;
  },
  getArticlespluscommande: async () => {
    const userToken_ = await await ls.get('userToken');
    const response = await (
      await fetch(baseUrl + "/articles4/get", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + userToken_
        }
      })
    ).json();
    return response;
  },
  getHkey: async () => {
    const userToken_ = await await ls.get('userToken');
    const response = await (
      await fetch(baseUrl + "/hkey/get", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + userToken_
        }
      })
    ).json();
    return response;
  },
  getGroupeToDropdown: async () => {
    const userToken_ = await await ls.get('userToken');
    const response = await (
      await fetch(baseUrl + "/groupe/gettodropdown", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + userToken_
        }
      })
    ).json();
    return response;
  },
  getinvoiceaccount: async () => {
    const userToken_ = await await ls.get('userToken');
    const response = await (
      await fetch(baseUrl + "/invoiceaccount/gettodropdown", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + userToken_
        }
      })
    ).json();
    return response;
  },
  getAllGroupes: async () => {
    const userToken_ = await await ls.get('userToken');
    const response = await (
      await fetch(baseUrl + "/groupe/getall", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + userToken_
        }
      })
    ).json();
    return response;
  },
    getmessages: async () => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
          await fetch(baseUrl + "/message/getall", {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + userToken_
            }
          })
        ).json();
        return response;
      },
    getAllCommandesEtEtat: async data => {
        const response = await (
          await fetch(baseUrl + "/commandesetetat/get", {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
            }
          })
        ).json();
        return response;
      },
      getAllCommandesEtEtattoken: async data => {
        const userToken_ = await await ls.get('userToken');

        const response = await (
          await fetch(baseUrl + "/commandesetetattoken/get", {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + userToken_

            }
          })
        ).json();
        return response;
      },
  getAllFactures: async data => {
    const response = await (
      await fetch(baseUrl + "/factures/get", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
                }
      })
    ).json();
    return response;
  },
  getAllFacturestoken: async data => {
    const userToken_ = await await ls.get('userToken');
    const response = await (
      await fetch(baseUrl + "/facturestoken/get", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + userToken_
        }
      })
    ).json();
    return response;
  },
  envoimessage: async data => {
    const userToken_ = await await ls.get('userToken');

    const response = await (
      await fetch(baseUrl + "/commercial/message", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + userToken_

        },
        body: JSON.stringify(data)
      })
    ).json();
    return response;
  },
  repondremessage: async data => {
    const userToken_ = await await ls.get('userToken');

    const response = await (
      await fetch(baseUrl + "/message/send", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + userToken_

        },
        body: JSON.stringify(data)
      })
    ).json();
    return response;
  },
  login: async data => {
    const response = await (
      await fetch(baseUrl + "/admins/login", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      })
    ).json();
    return response;
  },
  twofacteur: async data => {
    const response = await (
      await fetch(baseUrl + "/admins/validatetwofactor/" + data.langue + "/" + data.twofactor, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
    ).json();
    return response;
  },
  supprGroupe: async data => {
    const userToken_ = await await ls.get('userToken');
    const response = await (
        await fetch(baseUrl + "/groupe/delete/" + data.id + "/" + data.langue, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + userToken_
            },
        })
    ).json();
    return response;
},
    recuperermdp: async data => {
        const response = await (
            await fetch(baseUrl + "/admin/envoieMailPassword/" + data.email + "/" + data.langue, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
            })
        ).json();
        return response;
    },
    adminsave: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/admins/add", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    adminupdate: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/admins/update", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    adminupdateWithoutPassword: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/admins/updateWithoutPassword", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    supprMessages: async data => {
      const userToken_ = await await ls.get('userToken');
      const response = await (
          await fetch(baseUrl + "/message/delete", {
              method: "POST",
              headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + userToken_
              },
              body: JSON.stringify(data)
          })
      ).json();
      return response;
  }, 
    supprAdmin: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/admins/delete", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    excel: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/entrersortie/excel", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    getadmins: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/admins/getAll", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },




};
