import React, {useEffect, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
// import LoadingButton from '@mui/lab/LoadingButton';
import LoginIcon from '@mui/icons-material/Login';
import LoadingOverlay from 'react-loading-overlay';
// import ReactLoading from 'react-loading';
import {helpers} from '../../services/api/helpers'
import Modal from '@mui/material/Modal';
import { useLocation, useNavigate, Navigate    } from "react-router-dom"
import ls from 'local-storage'
import Lang from "../shared/SelectLangue";
import { baseUrl } from '../../services/constantes';
import { useCookies } from 'react-cookie';
const theme = createTheme();
import { useTranslation } from "react-i18next";

export const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const Authentication =()=> {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { i18n } = useTranslation();

    const [openSnack, setOpenSnack] = useState({isopenTwofactorError:false, isOpenEmailError:false,isOpenPasswordError:false, txt:''});
    const [openToast, setOpenToast] = useState({isOpenToast:false, txt:''});
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingBtnRecupPass, setIsLoadingBtnRecupPass] = useState(false);
    const [isSeSouvenir, setIsSeSouvenir] = useState(false);
    const [openModal, setOpenModal] = React.useState(false);
    const [passwordd, setPassword] = useState('');
    const [emaill, setEmail] = useState('');
    const [isShowTwoFactor, setShowTwoFactor] = useState(false);
    const [twofactor, seTwofactor] = useState('');
    const [cookies, setCookie, removeCookie] = useCookies(['cookies']);
    const [isopencookie, setOpencookie] = useState(true);

    let lsi18n = ls.get('lsi18n');
    useEffect(() => {
        if(lsi18n === null){
            lsi18n = i18n.language
        }else{
            i18n.changeLanguage( lsi18n,()=>null)
        }

        const localstorage = async () => {
           // removeCookie('name')
            const user_ = await JSON.parse(await ls.get('user'));
            if(user_ && user_.isSeSouvenir){
                setPassword(user_.password);
                setEmail(user_.email);
            }
            // , JSON.stringify({email, password, isSeSouvenir}
        };
        localstorage().catch(console.error);
    },[]);
    const handleSeSouvenir = (value) => {
        setIsSeSouvenir(value.target.checked)
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const email = data.get('email');
        const password = data.get('password');
        //navigate("dashboard", { replace: true });////////////////////////////////

        setIsLoading(true);

        if(email == ''){
            setOpenSnack({isOpenEmailError:true,txt:'Veuillez insérer votre e-mail'});
            setIsLoading(false);
            return;
        }
        setOpenSnack({...openSnack, isOpenEmailError:false});
        if( password == ''){
            setOpenSnack({isOpenPasswordError:true,txt:'Veuillez insérer votre mot de passe'});
            setIsLoading(false);
            return;
        }
        setOpenSnack({...openSnack, isOpenPasswordError:false});

        helpers
            .login({
                nom: email,
                password: password,
                langue: i18n.language
            })
            .then(async r => {
                setIsLoading(false);
                if (r.status === 200) {
                    setShowTwoFactor(true)
                } else {
                    setOpenToast({
                        isOpenToast: true,
                        txt: "Votre e-mail ou votre mot de passe ne correspond pas.",
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                }
            })
            .catch(e => {
                setIsLoading(false);
            });

    };



    const handleSubmitMotdepasseOublie = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const email = data.get('email');
        if(email == ''){
            setOpenSnack({isOpenEmail1Error:true,txt:'Veuillez insérer votre e-mail'});
            return;
        }
        setOpenSnack({...openSnack, isOpenEmail1Error:false});

        setIsLoadingBtnRecupPass(true);
        setTimeout(()=>{
            setIsLoadingBtnRecupPass(false);
            setOpenToast({  isOpenToast: true, txt: "Une erreur s'est produite, peut être que vous n'êtes pas connecté sur internet." });
            setOpenModal(false)
        },10000);

        helpers
            .recuperermdp({
                email: email,
                langue: i18n.language
            })
            .then(async r => {
                setIsLoadingBtnRecupPass(false);
                if (r.status === 200) {
                    setOpenToast({  isOpenToast: true, txt: r.message, });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                    setOpenModal(false)
                } else {
                    setOpenToast({ isOpenToast: true, txt: r.message, });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                }
            })
            .catch(e => {
                setOpenModal(false);
                setIsLoadingBtnRecupPass(false);
            });
    };


    const validateTwofactor = (event) => {
        event.preventDefault();
        setIsLoading(true);

        helpers
        .twofacteur({
            langue:i18n.language,
            twofactor: twofactor,
            langue: i18n.language
        })
        .then(async r => {
            setIsLoading(false);
            if (r.status === 200) {
                console.log(r)
                await ls.set('userToken', r.userToken);
                if(r.data.type == 'SUPERADMIN'){
                    await ls.set('user', JSON.stringify({email:r.data.email, password: r.data.password, isSeSouvenir, nom:r.data.nom, prenom:r.data.prenom, langue: r.data?.langue}));
                }else{
                await ls.set('user', JSON.stringify({groupe:r.data?.groupe.nom, groupeId:r.data?.groupe.id, email:r.data.email, password: r.data.password, isSeSouvenir, nom:r.data.nom, prenom:r.data.prenom, langue: r.data?.langue}));
               }
                await ls.set('xxxx', r.data.type == 'SUPERADMIN'? 'AAAA':'BBBB');
                await ls.set('ALLG', JSON.stringify([]));
                navigate("dashboard", { replace: true });
                window.location.reload()
                // window.location.replace(
                //      `${baseUrl}/dashboard`
                // )

            } else {
                setOpenToast({
                    isOpenToast: true,
                    txt: r.message,
                });
                setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
            }
        }).catch(e => {
            setIsLoading(false);
        });

    }

    



    return (
        <LoadingOverlay
            active={isLoading}
            spinner
            text='Chargement...'
        >
            <ThemeProvider theme={theme}>
                <Grid container component="main" sx={{ height: '100vh' }}>
                    <CssBaseline />
                    <Grid
                        item
                        xs={false}
                        sm={4}
                        md={7}
                        sx={{
                            // backgroundImage: 'url(http://myGlobalNet.fr/logo.png)',
                             backgroundImage: "url(/myglobalnet-Logo-vector.svg)",
                            backgroundRepeat: 'no-repeat',
                            backgroundColor: (t) =>
                                t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                            backgroundSize: 'contain',
                            backgroundPosition: 'center',
                        }}
                    />
                     <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                      <Box
                          sx={{
                                my: 8,
                                mx: 4,
                                display: 'flex',
                               flexDirection: 'column',
                                alignItems: 'center',
                                marginTop:20
                            }}
                        >
                            {/* <Avatar sx={{ m: 1, bgcolor: '#ff3959' }}> */}
                            <Avatar 
                            style={{backgroundColor: "#7DB634"}}
                            sx={{ m: 1, 
                            //bgcolor: 'secondary.main'
                             }}>
                               <LockOutlinedIcon />
                            </Avatar>
                            {isShowTwoFactor ?
                             <div>
                             <Typography style={{textAlign:'center'}} component="h1" variant="h5">
                             {t('login.doublefacteur')}
                             </Typography>
                             <Box component="form" noValidate onSubmit={validateTwofactor} sx={{ mt: 1 }}>
                                 <TextField
                                     margin="normal"
                                    required
                                     fullWidth
                                     type="numeric"
                                     id="twofactor"
                                     label= {t('login.coderecuparmail')}
                                     name="twofactor"
                                     autoFocus
                                     value={twofactor}
                                     onChange={(v)=>{v.preventDefault(); seTwofactor(v.target.value)}}
                                 />
                                <Collapse in={openSnack.isOpenTwofactorError}>
                                    <Alert severity="error" action={
                                        <IconButton
                                            aria-label="close"
                                            color="inherit"
                                             size="small"
                                             onClick={() => {
                                                setOpenSnack({...openSnack, isOpenTwofactorError:false});
                                             }}
                                         >
                                             <CloseIcon fontSize="inherit" />
                                     </IconButton>
                                    }>{openSnack.txt}</Alert>
                                 </Collapse>
                    
                                 <Button
                                     style={{backgroundColor: "#7DB634"}}
                                     onPress = {validateTwofactor}
                                     type="submit"
                                     fullWidth
                                     variant="contained"
                                     sx={{ mt: 3, mb: 2 }}
                                     startIcon={<LoginIcon fontSize="inherit" />}
                                 >
                                    {t('login.validercode')}
                                 </Button>
                                 
                                <Copyright sx={{ mt: 5 }} />
                            </Box>
                             </div>

                            :
                            <div>
                            <Typography style={{textAlign:'center'}} component="h1" variant="h5">
                            {t('login.seconnecter')}
                            </Typography>
                            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                                <TextField
                                    margin="normal"
                                   required
                                    fullWidth
                                    type="email"
                                    id="email"
                                    label= {t('login.email')}
                                    name="email"
                                  //  autoComplete="email"
                                    //autoFocus
                                    value={emaill}
                                    onChange={(v)=>{v.preventDefault(); setEmail(v.target.value)}}
                                />
                               <Collapse in={openSnack.isOpenEmailError}>
                                   <Alert severity="error" action={
                                       <IconButton
                                           aria-label="close"
                                           color="inherit"
                                            size="small"
                                            onClick={() => {
                                               setOpenSnack({...openSnack, isOpenEmailError:false});
                                            }}
                                        >
                                            <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                   }>{openSnack.txt}</Alert>
                                </Collapse>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label={t('login.motdepasse')}
                                    type="password"
                                    id="password"
                                   // autoComplete="current-password"
                                    value={passwordd}
                                    onChange={function (v){v.preventDefault(); setPassword(v.target.value)}}
                                />
                               <Collapse in={openSnack.isOpenPasswordError}>
                                    <Alert severity="error" action={
                                       <IconButton
                                           aria-label="close"
                                           color="inherit"
                                            size="small"
                                           onClick={() => {
                                                setOpenSnack({...openSnack, isOpenPasswordError:false});
                                            }}
                                        >
                                            <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                    }>{openSnack.txt}</Alert>
                                </Collapse>
                                <FormControlLabel
                                    control={<Checkbox onChange={handleSeSouvenir} value="remember" color="primary" />}
                                    label= {t('login.sesouvenirdemoi')}
                                />

                                <Button
                                style={{backgroundColor: "#7DB634"}}
                                    onPress = {handleSubmit}
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 2, mb: 2 }}
                                    startIcon={<LoginIcon fontSize="inherit" />}
                                >
                                    {t('login.seconnecter')}
                                </Button>

                                
                                <Lang lsi18n={lsi18n} />


                                <Grid style={{marginTop:20}} container>
                                    <Grid item xs>
                                        <div style={{cursor:"pointer"}}   onClick={()=>setOpenModal(true)}>
                                            <Link  variant="body2">
                                            {t('login.Motdepasseoublié')}
                                            </Link>
                                        </div>
                                    </Grid>
                                </Grid>
                               <Copyright sx={{ mt: 5 }} />
                           </Box>
                            </div>
                            }                           
                    
                         
                       </Box>
                    </Grid> 
                   { (!cookies.name && isopencookie  ) && <div  style={{  backgroundColor: "#e6e6e6", width: "100%", height: 100, position: "fixed", bottom: 0, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}} className="ng-star-inserted">
                    <div  style={{marginLeft: 25, width: "60%"}}> {t('login.cookie')}<a  target="_blank" href=". https://www.globalnet.be/fr/helpdesk/disclaimerenprivacy" style={{color: "#80ba27", marginLeft: 25, width: "60%"}}> https://www.globalnet.be/fr/helpdesk/disclaimerenprivacy </a>
                    </div>
                    <div  style={{display: "flex", flexDirection: "row", alignItems: "center", paddingRight: 20}}>
                  
                        <Button   onClick={(e)=>{
                                            e.preventDefault()
                                           setOpencookie(false)
                                        }}
                                         name="fermer" id="fermer" required="" type="button" className="btn btn-block login-btn mb-4" style={{color: "#ffffff", backgroundColor: "#db0909", marginTop: 7}} value="Fermer" >
                                {t('login.fermer')}
                            </Button>
                            <Button onClick={(e)=>{
                                          const date = JSON.stringify(new Date())
                                          setCookie('name', date);
                                          setOpencookie(false)
                                        }}
                                         name="cookie" id="cookie" required="" type="button" className="btn btn-block login-btn mb-4" style={{color: "#ffffff", backgroundColor: "#1fb820", marginLeft: 25, minWidth:300}} value="Autoriser les cookies">
                                 {t('login.autorisercookie')}
                            </Button>
                            </div>
                            </div> }
                </Grid>

                <Snackbar
                    open={openToast.isOpenToast}
                    autoHideDuration={6000}
                    //onClose={()=>setOpenToast({...openToast, isOpenToast:false})}
                    message={openToast.txt}
                    action={<React.Fragment>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={(event, reason) => {
                                if (reason === 'clickaway') {
                                    return;
                                }
                                setOpenToast(false);
                            }}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>}
                />
                <Modal
                    open={openModal}
                    onClose={()=>setOpenModal(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{overflow: 'scroll' }}
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        //borerRadius:'15px',
                        borderRadius: '5px!important',
                        boxShadow: 24,
                        p: 4,
                    }}>
                        <Typography id="modal-modal-title" variant="h6" component="h3">
                        {t('login.recuperationpass')}
                        </Typography>
                        <Box component="form" noValidate onSubmit={handleSubmitMotdepasseOublie} sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label={t('login.email')}
                                name="email"
                                autoComplete="email"
                                autoFocus
                            />
                            <Collapse in={openSnack.isOpenEmail1Error}>
                                <Alert severity="error" action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            setOpenSnack({...openSnack, isOpenEmail1Error:false});
                                        }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }>{openSnack.txt}</Alert>
                            </Collapse>
                            <Button
                                onPress = {handleSubmitMotdepasseOublie}
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                startIcon={
                                    isLoadingBtnRecupPass && <img width={15} src={require("../../assets/loading-load.gif").default} alt="wait..." />
                                    // <LoginIcon fontSize="inherit" />
                                }
                            >
                               {t('login.recuperer')}
                            </Button>
                        </Box>
                    </Box>
                </Modal>
            </ThemeProvider>
        </LoadingOverlay>
    );
};


function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://about.globalnet.be" >
            Bunzl - Global Net
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}


