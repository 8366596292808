import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import Button from "@mui/material/Button";
import {helpers} from "../../services/api/helpers";
import Snackbar from "@mui/material/Snackbar/Snackbar";
import LoadingOverlay from "react-loading-overlay";
import DataTable from 'react-data-table-component';
import 'react-data-table-component-extensions/dist/index.css';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import { useLocation, useNavigate } from "react-router-dom"
import Toll from '@mui/icons-material/Toll';
import ls from 'local-storage'
let facture = ls.get('facture.groupe');
import { useTranslation } from "react-i18next";


const columns = ((clickHandler, onClickButtonlist) => [
    {
        name: 'N° facture',
        selector: row => row.bestinvoic_facture.MessageNumber,//message number du facture, car il y a plusieurs message number , ie , suivant files
        wrap:true,
        cell: (row) => <Link to="/dashboard/facture"  className="link">
                    <div  
                //     onClick={()=>{
                //     const from =  `/dashboard/facture`;
                //     navigate(from, { replace: true });    
                // }} 
                 title={(row.bestinvoic_facture.InvoiceName != null && row.bestinvoic_facture.InvoiceName !== '' && row.bestinvoic_facture.InvoiceName !== undefined) ? row.bestinvoic_facture.InvoiceName : '' + ' ' +  (row.bestinvoic_facture.InvoiceAddress != null && row.bestinvoic_facture.InvoiceAddress !== '' && row.bestinvoic_facture.InvoiceAddress !== undefined) ? row.bestinvoic_facture.InvoiceAddress : ''} style={{ }}>{row.bestinvoic_facture.MessageNumber}</div>
   
            </Link> },
    
    
    {
        name: 'Reference',
        selector: row => row.BunzlItemID,
        wrap:true,
        sortable:true,
        cell: (row) => <div 
        style={{ }}>{row.BunzlItemID}</div>
    },
    {
        name: 'Articles',
        selector: row => row.Primary,
        wrap:true,
        sortable:true,
        cell: (row) => <div 
        title={(row.bestinvoic_facture.InvoiceName != null && row.bestinvoic_facture.InvoiceName !== '' && row.bestinvoic_facture.InvoiceName !== undefined) ? row.bestinvoic_facture.InvoiceName : '' + ' ' +  (row.bestinvoic_facture.InvoiceAddress != null && row.bestinvoic_facture.InvoiceAddress !== '' && row.bestinvoic_facture.InvoiceAddress !== undefined) ? row.bestinvoic_facture.InvoiceAddress : ''} 
        style={{ }}>{row.Primary}</div>
    },
    // {
    //     name: 'Secundary',
    //     selector: row => row.Secundary,
    //     wrap:true,
    //     sortable:true,
    //     cell: (row) => <div 
    //     title={(row.bestinvoic_facture.InvoiceName != null && row.bestinvoic_facture.InvoiceName !== '' && row.bestinvoic_facture.InvoiceName !== undefined) ? row.bestinvoic_facture.InvoiceName : '' + ' ' +  (row.bestinvoic_facture.InvoiceAddress != null && row.bestinvoic_facture.InvoiceAddress !== '' && row.bestinvoic_facture.InvoiceAddress !== undefined) ? row.bestinvoic_facture.InvoiceAddress : ''} 
    //     style={{ }}>{row.Secundary}</div>
    // },
    {
        name: 'Quantité',
        selector: row => row.Quantity,
        wrap:true,
        sortable:true,
        cell: (row) => <div 
        title={(row.bestinvoic_facture.InvoiceName != null && row.bestinvoic_facture.InvoiceName !== '' && row.bestinvoic_facture.InvoiceName !== undefined) ? row.bestinvoic_facture.InvoiceName : '' + ' ' +  (row.bestinvoic_facture.InvoiceAddress != null && row.bestinvoic_facture.InvoiceAddress !== '' && row.bestinvoic_facture.InvoiceAddress !== undefined) ? row.bestinvoic_facture.InvoiceAddress : ''} 
        style={{ }}>{row.Quantity}</div>
    },
    {
        name: 'Conditionnement',
        selector: row => row.SalesUnit,
        wrap:true,
        sortable:true,
        cell: (row) => <div 
        title={(row.bestinvoic_facture.InvoiceName != null && row.bestinvoic_facture.InvoiceName !== '' && row.bestinvoic_facture.InvoiceName !== undefined) ? row.bestinvoic_facture.InvoiceName : '' + ' ' +  (row.bestinvoic_facture.InvoiceAddress != null && row.bestinvoic_facture.InvoiceAddress !== '' && row.bestinvoic_facture.InvoiceAddress !== undefined) ? row.bestinvoic_facture.InvoiceAddress : ''} 
        style={{ }}>{row.SalesUnit}</div>
    },
    // { toogle a la demande
    //     name: 'Prix unitaire',
    //     selector: row => row.GrossPrice,
    //     wrap:true,
    //     sortable:true,
    //     cell: (row) => <div 
    //     title={(row.bestinvoic_facture.InvoiceName != null && row.bestinvoic_facture.InvoiceName !== '' && row.bestinvoic_facture.InvoiceName !== undefined) ? row.bestinvoic_facture.InvoiceName : '' + ' ' +  (row.bestinvoic_facture.InvoiceAddress != null && row.bestinvoic_facture.InvoiceAddress !== '' && row.bestinvoic_facture.InvoiceAddress !== undefined) ? row.bestinvoic_facture.InvoiceAddress : ''} 
    //     style={{ }}>{row.GrossPrice}</div>
    // },


    // {
    //     name: 'VATGroup',
    //     selector: row => row.VATGroup,
    //     wrap:true,
    //     sortable:true,
    //     cell: (row) => <div 
    //     title={(row.bestinvoic_facture.InvoiceName != null && row.bestinvoic_facture.InvoiceName !== '' && row.bestinvoic_facture.InvoiceName !== undefined) ? row.bestinvoic_facture.InvoiceName : '' + ' ' +  (row.bestinvoic_facture.InvoiceAddress != null && row.bestinvoic_facture.InvoiceAddress !== '' && row.bestinvoic_facture.InvoiceAddress !== undefined) ? row.bestinvoic_facture.InvoiceAddress : ''} 
    //     style={{ }}>{row.VATGroup}</div>
    // },
    // {
    //     name: 'Date de livraison',
    //     selector: row => row.DeliveryDate,
    //     wrap:true,
    //     sortable:true,
    //     cell: (row) => <div 
    //     title={(row.bestinvoic_facture.InvoiceName != null && row.bestinvoic_facture.InvoiceName !== '' && row.bestinvoic_facture.InvoiceName !== undefined) ? row.bestinvoic_facture.InvoiceName : '' + ' ' +  (row.bestinvoic_facture.InvoiceAddress != null && row.bestinvoic_facture.InvoiceAddress !== '' && row.bestinvoic_facture.InvoiceAddress !== undefined) ? row.bestinvoic_facture.InvoiceAddress : ''} 
    //     style={{ }}>{row.DeliveryDate}</div>
    // },
 
    // {
    //     name: 'Groupe',
    //     selector: row => {
    //         const parsegroupe = JSON.parse(facture);
    //         if(parsegroupe.nom)
    //         return parsegroupe.nom;
    //     },
    //     sortable:true,
    //     wrap:true,
    //     style:{fontSize:16},
    // },
    // {
    //     name: '',
    //     wrap:true,
    //     button: true,
    //     cell: (row) => <div style={{ }}><div  onClick={onClickButtonlist} id={row.id} style={{fontSize:11, color: 'red'}}>*<Toll />*</div></div>,}
]);


export const Centrefactureinventable = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { i18n } = useTranslation();

    const [isshowmodal, setshowmodal] = useState(false);
    const [openSnack, setOpenSnack] = useState({
        isOpenNomError:false,
        txt:''});
    const [openToast, setOpenToast] = useState({isOpenToast:false, txt:''});
    const [tables, setTables] = React.useState([]);
    const [isLoading, setLoading] = React.useState(false);
    const [searchField, setSearchField] = useState("");
    const [detailToShow, setDetailToShow] = useState(null);
    
    const goToFacture = (val) => {
        const from =  `/dashboard/facture`;
        navigate(from, { replace: true })
    };
    
    const onClickButtonlist = (val) => {
      
        console.log("ok")
    };
    
    

    useEffect(()=>{
        const filteredTables = tables && tables.filter(
            item => {
                return (
                    item.nom !== null && item.nom
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                    item.adresse !== null && item.adresse
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                    item.commentaire !== null && item.commentaire
                        .toLowerCase()
                        .includes(searchField.toLowerCase())
                );
            }
        );
        if(searchField == '')
        getinvoic_Lines().catch(console.error);
        if(filteredTables !== undefined)
       setTables(filteredTables);
    },[searchField]);

    function rechercheindb(){
        if(searchField != ''){
            return;
        }
        return;
    }


    const getinvoic_Lines = async () => {
        const invoiclines = await JSON.parse(await ls.get('invoic_Lines'));
        setTables(invoiclines);
        setLoading(false);

    };

    useEffect(()=>{
        setLoading(true);
        getinvoic_Lines().catch(console.error);
    },[]);


    const selectProps = { indeterminate: isIndeterminate => isIndeterminate };


  return (
      <LoadingOverlay
          active={isLoading}
          spinner
          text='Chargement...'
      >
      <div className="content-wrapper">
        <div className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
               

                    <div className="col-sm-6">
                        <h1 className="m-0 text-dark">Articles</h1>
                    </div>
                    <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                <Link to="/dashboard/facture"  className="link">
                    {"<     Retour aux Factures"}
                </Link>
                </ol>
                </div>
                   {/*  <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item">
                                    Articles
                            </li>
                            <li className="breadcrumb-item">
                            <Link to="/dashboard/facture"  className="link">
                            Factures
                                </Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <Link to="/dashboard"  className="link">
                                myGlobalNet
                                </Link>
                            </li>
                        </ol>
                    </div> */}
                </div>{/* /.row */}
            </div>{/* /.container-fluid */}
        </div>

          {/*le datatable*/}
          <div className="card">
              <div className="card-body">
                  <Paper
                      component="form"
                      sx={{ p: '2px 2px', display: 'flex', alignItems: 'center', width: 200 }}
                  >
                      <InputBase
                          sx={{ ml: 1, flex: 1 }}
                          placeholder="Rechercher"
                          inputProps={{ 'aria-label': 'Rechercher' }}
                          onChange={(e)=>{
                              e.preventDefault();
                              setSearchField(e.target.value);
                          }}
                          value={searchField}
                      />
                      <IconButton  onClick={() => {
                          rechercheindb()
                      }} type="button" sx={{ p: '5px' }} aria-label="search">
                          <SearchIcon />
                      </IconButton>
                  </Paper>


                  <DataTable
                      pagination
                      selectableRowsComponentProps={selectProps}
                      dense                
                      columns={columns(goToFacture, onClickButtonlist)}
                      data={tables}
                      striped
                      highlightOnHover
                      persistTableHead
                      fixedHeader={false}
                      progressPending={false}
                      noDataComponent={"Aucune données à afficher"}
                      onRowClicked={(row, event) => {console.log('row clicked', row);
                      setDetailToShow(row); 
                      setshowmodal(true);
                      }}
                      paginationComponentOptions={{ rowsPerPageText: 'Lignes par page:', rangeSeparatorText: 'of', noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All' }}
                  />
              </div>
          </div>

          
          <Modal
              open={false}
              onClose={()=>setshowmodal(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              style={{overflow: 'scroll' }}

          >
              <div className="modal-dialog ">
                  <div className="modal-content">
                      <div className="modal-header">
                          <h4 className="modal-title">Détails du facture</h4>
                          <button onClick={()=>setshowmodal(false)} type="button" className="close" data-dismiss="modal" aria-label="Fermer">
                              <span aria-hidden="true">&times;</span>
                          </button>
                      </div>
                      <div   className="modal-body">
                      <div   className="">
                        Facture : {detailToShow !== null && detailToShow.bestinvoic_facture.MessageNumber}
                        </div>
                        <div   className="">
                        Reference : {detailToShow !== null && detailToShow.BunzlItemID}
                        </div>
                        <div   className="">
                        MessageDate : {detailToShow !== null && detailToShow.bestinvoic_facture.MessageDate}
                        </div>
                        <div   className="">
                        SourceEndpoint : {detailToShow !== null && detailToShow.bestinvoic_facture.SourceEndpoint}
                        </div>
                        <div   className="">
                        N° client de livraison : {detailToShow !== null && detailToShow.bestinvoic_facture.DeliveryAccount}
                        </div>
                        <div   className="">
                        Adresse de livraison : {detailToShow !== null && detailToShow.bestinvoic_facture.DeliveryAddress}
                        </div>
                        <div   className="">
                        Compte client : {detailToShow !== null && detailToShow.bestinvoic_facture.DeliveryName}
                        </div>
                        <div   className="">
                        Livraison effectué par : {detailToShow !== null && detailToShow.bestinvoic_facture.ModeOfDelivery}
                        </div>
                        <div   className="">
                        TermsOfDelivery : {detailToShow !== null && detailToShow.bestinvoic_facture.TermsOfDelivery}
                        </div>
                        <div   className="">
                        OrderAccount : {detailToShow !== null && detailToShow.bestinvoic_facture.OrderAccount}
                        </div>
                        <div   className="">
                        N° client de facturation : {detailToShow !== null && detailToShow.bestinvoic_facture.InvoiceAccount}
                        </div>
                        <div   className="">
                        Adresse de facturation : {detailToShow !== null && detailToShow.bestinvoic_facture.InvoiceAddress}
                        </div>
                        <div   className="">
                        Compte client de facturation : {detailToShow !== null && detailToShow.bestinvoic_facture.InvoiceName}
                        </div>
                        <div   className="">
                        SupplierAddress : {detailToShow !== null && detailToShow.bestinvoic_facture.SupplierAddress}
                        </div>
                        <div   className="">
                        SupplierName : {detailToShow !== null && detailToShow.bestinvoic_facture.SupplierName}
                        </div>
                        <div   className="">
                        montant HT : {detailToShow !== null && detailToShow.bestinvoic_facture.AmountExVAT}
                        </div>
                        <div   className="">
                        AmountInclVAT : {detailToShow !== null && detailToShow.bestinvoic_facture.AmountInclVAT}
                        </div>
                        <div   className="">
                        Currency : {detailToShow !== null && detailToShow.bestinvoic_facture.Currency}
                        </div>
                        <div   className="">
                        Votre référence : {detailToShow !== null && detailToShow.bestinvoic_facture.CustomerReference}
                        </div>
                        <div   className="">
                        Commande n° : {detailToShow !== null && detailToShow.bestinvoic_facture.SONumber}
                        </div>
                        <div   className="">
                        Commande du : {detailToShow !== null && detailToShow.bestinvoic_facture.SODate}
                        </div>
                        <div   className="">
                        filename : {detailToShow !== null && detailToShow.bestinvoic_facture.filename}
                        </div>
                        
                        <div   className="">
                        Articles : 
                        </div>
                      {detailToShow !== null && detailToShow.Primary}

                    </div>
                  </div>
              </div>
          </Modal>
          <Snackbar
              open={openToast.isOpenToast}
              autoHideDuration={6000}
              //onClose={()=>setOpenToast({...openToast, isOpenToast:false})}
              message={openToast.txt}
              action={<React.Fragment>
                  <IconButton
                      size="small"
                      aria-label="fermer"
                      color="inherit"
                      onClick={(event, reason) => {
                          if (reason === 'clickaway') {
                              return;
                          }
                          setOpenToast(false);
                      }}
                  >
                      <CloseIcon fontSize="small" />
                  </IconButton>
              </React.Fragment>}
          />
      </div>
          
      </LoadingOverlay>
  )
};
