import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import Button from "@mui/material/Button";
import {helpers} from "../../services/api/helpers";
import Snackbar from "@mui/material/Snackbar/Snackbar";
import LoadingOverlay from "react-loading-overlay";
import DataTable from 'react-data-table-component';
import 'react-data-table-component-extensions/dist/index.css';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import { useLocation, useNavigate } from "react-router-dom"
import Toll from '@mui/icons-material/Toll';
import ls from 'local-storage'
import SaveIcon from '@mui/icons-material/Save';
import { useTranslation } from "react-i18next";
let isAdmin = ls.get('xxxx');


const columns = ((clickHandler, onClickButtonlist, onClickButtonfacture) => [

    {
        name: 'Commande n°',
        selector: row => row.facture.SONumber,
        wrap:true,
        sortable:true,
        cell: (row) => <div 
        title={(row.facture.InvoiceName != null && row.facture.InvoiceName !== '' && row.facture.InvoiceName !== undefined) ? row.facture.InvoiceName : '' + ' ' +  (row.facture.InvoiceAddress != null && row.facture.InvoiceAddress !== '' && row.facture.InvoiceAddress !== undefined) ? row.facture.InvoiceAddress : ''}
        style={{ }}>{row.facture.SONumber} du {row.facture.SODate}</div>
    },
    {
        name: 'Destination',
        selector: row => row?.facture?.DeliveryAccount,
        wrap:true,
        sortable:true,
        cell: (row) => <div 
        style={{ }}><span style={{color:'purple'}}>{row?.facture?.DeliveryAccount}</span> {row?.facture?.DeliveryName}</div>
    },
    {
        name: 'N° facture',
        selector: row => row.facture.MessageNumber,//message number du facture, car il y a plusieurs message number , ie , suivant files
        wrap:true,
        cell: (item) => <div title={(item.facture.InvoiceName != null && item.facture.InvoiceName !== '' && item.facture.InvoiceName !== undefined) ? item.facture.InvoiceName : '' + ' ' +  (item.facture.InvoiceAddress != null && item.facture.InvoiceAddress !== '' && item.facture.InvoiceAddress !== undefined) ? item.facture.InvoiceAddress : ''} style={{ }}>{item.facture.MessageNumber}</div>
    },
    {
        name: 'N° client de livraison',
        selector: row => row.facture.DeliveryAccount,
        wrap:true,
        sortable:true,
        cell: (item) => <div title={(item.facture.InvoiceName != null && item.facture.InvoiceName !== '' && item.facture.InvoiceName !== undefined) ? item.facture.InvoiceName : '' + ' ' +  (item.facture.InvoiceAddress != null && item.facture.InvoiceAddress !== '' && item.facture.InvoiceAddress !== undefined) ? item.facture.InvoiceAddress : ''} style={{ }}>{item.facture.DeliveryAccount}</div>
    },
    {
        name: 'Votre référence',
        selector: row => row.facture.CustomerReference,
        wrap:true,
        sortable:true,
        cell: (row) => <div title={(row.facture.InvoiceName != null && row.facture.InvoiceName !== '' && row.facture.InvoiceName !== undefined) ? row.facture.InvoiceName : '' + ' ' +  (row.facture.InvoiceAddress != null && row.facture.InvoiceAddress !== '' && row.facture.InvoiceAddress !== undefined) ? row.facture.InvoiceAddress : ''} style={{ }}>{row.facture.CustomerReference}</div>

    },
    {
        name: 'montant HT',
        selector: row => row.facture.AmountExVAT,
        wrap:true,
        sortable:true,
        cell: (row) => <div title={(row.facture.InvoiceName != null && row.facture.InvoiceName !== '' && row.facture.InvoiceName !== undefined) ? row.facture.InvoiceName : '' + ' ' +  (row.facture.InvoiceAddress != null && row.facture.InvoiceAddress !== '' && row.facture.InvoiceAddress !== undefined) ? row.facture.InvoiceAddress : ''} style={{ }}>{row.facture.AmountExVAT}</div>

    },
    {
        name: 'TVA',
        selector: row => row.facture.VAT,
        wrap:true,
        sortable:true,
        cell: (row) => <div title={(row.facture.InvoiceName != null && row.facture.InvoiceName !== '' && row.facture.InvoiceName !== undefined) ? row.facture.InvoiceName : '' + ' ' +  (row.facture.InvoiceAddress != null && row.facture.InvoiceAddress !== '' && row.facture.InvoiceAddress !== undefined) ? row.facture.InvoiceAddress : ''} style={{ }}>{row.facture.VAT}</div>

    },
    {
        name: 'montantTTC',
        selector: row => row.facture.AmountInclVAT,
        wrap:true,
        sortable:true,
        cell: (row) => <div title={(row.facture.InvoiceName != null && row.facture.InvoiceName !== '' && row.facture.InvoiceName !== undefined) ? row.facture.InvoiceName : '' + ' ' +  (row.facture.InvoiceAddress != null && row.facture.InvoiceAddress !== '' && row.facture.InvoiceAddress !== undefined) ? row.facture.InvoiceAddress : ''} style={{ }}>{row.facture.AmountInclVAT}</div>

    },
    {
        name: 'Détails de la facture',
        wrap:true,
        button: true,
        //cell: (row) => <div style={{ }}><span style={{paddingTop:4, paddingBottom:4, paddingLeft:6, paddingRight:6, backgroundColor:'#75ff42', borderRadius:10}}>{row?.facture.invoic_Lines.length}</span><Button className={"btn btn-success px-1"} onClick={(row)=>clickHandler(row)} id={row.facture.id} style={{fontSize:11}}>Articles</Button></div>,
        cell: (row) =>  <Link to="/dashboard/facture/invoicline"  className="link">
                <div
                title={(row.facture.InvoiceName != null && row.facture.InvoiceName !== '' && row.facture.InvoiceName !== undefined) ? row.facture.InvoiceName : '' + ' ' +  (row.facture.InvoiceAddress != null && row.facture.InvoiceAddress !== '' && row.facture.InvoiceAddress !== undefined) ? row.facture.InvoiceAddress : ''}
                style={{ }}><span style={{paddingTop:4, paddingBottom:4, paddingLeft:6, paddingRight:6, backgroundColor:'#75ff42', borderRadius:10}}>{row?.facture.invoic_Lines.length}</span><Button className={"btn btn-success px-1"}
                onClick={async function (e){
                    //let facture = {...row};
                    //await delete facture.invoic_Lines;
                    // let newformatagejsoninvoicline = [] ;

                    // for await (let invoic of row.facture.invoic_Lines){
                    //     let ax = invoic;
                    //     await console.log('invoic', invoic)
                    //      ax['facture'] = row;
                    //      await console.log('ax', ax)
                    //     await newformatagejsoninvoicline.push (ax)
                    // }
                    await ls.set('invoic_Lines', JSON.stringify(row.facture.invoic_Lines));
                    if(row.groupe !== null)
                    await ls.set('facture.groupe', JSON.stringify(row.groupe));
                    else
                    await ls.set('facture.groupe', JSON.stringify({}));
                    // const navigate = useNavigate();
                    // const from =  `/dashboard/facture/invoic_Lines`;
                    // navigate(from, { replace: true })     
                }} id={row.facture.id} style={{fontSize:11}}>Articles</Button></div>
                </Link>,

    },

    // {
    //     name: 'Facture',
    //     sortable:true,
    //     wrap:true,
    //     style:{fontSize:16},
    //     cell: (row) => {
    //         ls.set('filefacture', JSON.stringify(row.facture));
    //         return  <div style={{ }}><div title="facture" onClick={onClickButtonfacture} id={row.id} style={{fontSize:11, color: 'blue'}}>*<SaveIcon />*</div></div>

    //     }
        
    // },
        {
        name: 'Groupe',
        selector: row => {
            if(row.groupe && row.groupe.nom)
            return row.groupe.nom;
            else 
            return ""
        },
        sortable:true,
        wrap:true,
        style:{fontSize:16},
        cell: (row) => <div title={(row.facture.InvoiceName != null && row.facture.InvoiceName !== '' && row.facture.InvoiceName !== undefined) ? row.facture.InvoiceName : '' + ' ' +  (row.facture.InvoiceAddress != null && row.facture.InvoiceAddress !== '' && row.facture.InvoiceAddress !== undefined) ? row.facture.InvoiceAddress : ''} style={{ }}>{row.groupe?.nom}</div>
    },
 
    // { 
    //     name: '',
    //     wrap:true,
    //     button: true,
    //     cell: (row) => <div
    //     title={(row.facture.InvoiceName != null && row.facture.InvoiceName !== '' && row.facture.InvoiceName !== undefined) ? row.facture.InvoiceName : '' + ' ' +  (row.facture.InvoiceAddress != null && row.facture.InvoiceAddress !== '' && row.facture.InvoiceAddress !== undefined) ? row.facture.InvoiceAddress : ''}
    //     style={{ }}><div  onClick={onClickButtonlist} id={row.facture.id} style={{fontSize:11, color: 'red'}}>*<Toll />*</div></div>,}
]);


export const Facture = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { i18n } = useTranslation();

    const [isshowmodal, setshowmodal] = useState(false);
    const [isLoadingSave, setLoadingSave] = useState(false);
    const [openSnack, setOpenSnack] = useState({
        isOpenNomError:false,
        txt:''});
    const [openToast, setOpenToast] = useState({isOpenToast:false, txt:''});
    const [tables, setTables] = React.useState([]);
    const [isLoading, setLoading] = React.useState(false);
    const [searchField, setSearchField] = useState("");
    const [detailToShow, setDetailToShow] = useState(null);
    
    const goToinvoic_Lines = (val) => {
       // const from =  `/dashboard/facture/iventtable/${val.target.id}`;
        const from =  `/dashboard/facture/iventtable`;
        navigate(from, { replace: true })
    };
    
    const onClickButtonlist = (val) => {
      
        console.log("ok")
    };

       const     onClickButtonfacture     =async (val) => {

        const from =  `/dashboard/facture/down`;
        //navigate(from, { replace: true })
        window.open(from,'_blank', 'rel=noopener noreferrer')

        console.log("ok")
        };
    

    useEffect(()=>{
        const filteredTables = tables && tables.filter(
            item => {
                return (
                    item.nom !== null && item.nom
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                    item.adresse !== null && item.adresse
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                    item.commentaire !== null && item.commentaire
                        .toLowerCase()
                        .includes(searchField.toLowerCase())
                );
            }
        );
        if(searchField == '')
            getFactures().catch(console.error);
        if(filteredTables !== undefined)
       setTables(filteredTables);
    },[searchField]);

    function rechercheindb(){
        if(searchField != ''){
            return;
        }
        return;

    }



    const getFactures = async () => {

        if(isAdmin === "AAAA")
        helpers
            .getAllFactures()
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    setTables( r.data);
                }
            })
            else
            helpers
            .getAllFacturestoken()
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    setTables( r.data);
                }
            })
    };

    useEffect(()=>{
        setLoading(true);
        getFactures().catch(console.error);
    },[]);


    const selectProps = { indeterminate: isIndeterminate => isIndeterminate };


  return (
      <LoadingOverlay
          active={isLoading}
          spinner
          text='Chargement...'
      >
      <div className="content-wrapper">
        <div className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0 text-dark">Mes Factures</h1>
                    </div>{/* /.col */}
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item">
                                    Factures
                            </li>
                            <li className="breadcrumb-item active">
                                <Link to="/dashboard"  className="link">
                                myGlobalNet
                                </Link>
                            </li>
                        </ol>
                    </div>{/* /.col */}
                </div>{/* /.row */}
            </div>{/* /.container-fluid */}
        </div>

          {/*le datatable*/}
          <div className="card">
              <div className="card-body">
                  <Paper
                      component="form"
                      sx={{ p: '2px 2px', display: 'flex', alignItems: 'center', width: 200 }}
                  >
                      <InputBase
                          sx={{ ml: 1, flex: 1 }}
                          placeholder="Rechercher"
                          inputProps={{ 'aria-label': 'Rechercher' }}
                          onChange={(e)=>{
                              e.preventDefault();
                              setSearchField(e.target.value);
                          }}
                          value={searchField}
                      />
                      <IconButton  onClick={() => {
                          rechercheindb()
                      }} type="button" sx={{ p: '5px' }} aria-label="search">
                          <SearchIcon />
                      </IconButton>
                  </Paper>


                  <DataTable
                      pagination
                     // selectableRowsComponent={Checkbox}
                      selectableRowsComponentProps={selectProps}
                     // sortIcon={sortIcon}
                      dense
                      //{...props}
                      //title="Administration"
                     // theme={'dark'}
                     //  columns={columns}
                      columns={columns(goToinvoic_Lines, onClickButtonlist, onClickButtonfacture)}

                      data={tables}
                      striped
                      highlightOnHover
                      persistTableHead
                      fixedHeader={false}
                      progressPending={false}
                      noDataComponent={"Aucune données à afficher"}
                      onRowClicked={async(row, event) => {
                         console.log('RRRRROW', row)
                         setDetailToShow(row);
                       
                          setshowmodal(true);
                      }}
                      paginationComponentOptions={{ rowsPerPageText: 'Lignes par page:', rangeSeparatorText: 'of', noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All' }}
                  />
              </div>
              {/*fin body*/}
          </div>

          
          <Modal
              open={false}
              //open={isshowmodal}
              onClose={()=>setshowmodal(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              style={{overflow: 'scroll' }}

          >
              <div className="modal-dialog ">
                  <div className="modal-content">
                      <div className="modal-header">
                          <h4 className="modal-title">Détails de la facture</h4>
                          <button onClick={()=>setshowmodal(false)} type="button" className="close" data-dismiss="modal" aria-label="Fermer">
                              <span aria-hidden="true">&times;</span>
                          </button>
                      </div>

                      <div   className="modal-body">
                      <div   className="">
                      <span style={{fontWeight:'bolder'}}>facture :</span> {detailToShow !== null && detailToShow.facture.MessageNumber}
                        </div>
                        <div   className="">
                        <span style={{fontWeight:'bolder'}}>N° client de facturation :</span> {detailToShow !== null && detailToShow.facture.InvoiceAccount}
                        </div>
                        <div   className="">
                        <span style={{fontWeight:'bolder'}}>Adresse de facturation :</span> {detailToShow !== null && detailToShow.facture.InvoiceAddress}
                        </div>
                        <div   className="">
                        <span style={{fontWeight:'bolder'}}>N° client de livraison :</span> {detailToShow !== null && detailToShow.facture.DeliveryAccount}
                        </div>
                        <div   className="">
                        <span style={{fontWeight:'bolder'}}>Adresse de livraison :</span> {detailToShow !== null && detailToShow.facture.DeliveryAddress}
                        </div>
                        <div   className="">
                        <span style={{fontWeight:'bolder'}}>Montant HT :</span> {detailToShow !== null && detailToShow.facture.AmountExVAT}
                        </div>
                        {/* <div   className="">
                        <span style={{fontWeight:'bolder'}}>MessageDate :</span> {detailToShow !== null && detailToShow.facture.MessageDate}
                        </div>
                        <div   className="">
                        <span style={{fontWeight:'bolder'}}>SourceEndpoint :</span> {detailToShow !== null && detailToShow.facture.SourceEndpoint}
                        </div>
                
                        <div   className="">
                        <span style={{fontWeight:'bolder'}}>Compte client :</span> {detailToShow !== null && detailToShow.facture.DeliveryName}
                        </div>
                        <div   className="">
                        <span style={{fontWeight:'bolder'}}>Livraison effectué par :</span> {detailToShow !== null && detailToShow.facture.ModeOfDelivery}
                        </div>
                        <div   className="">
                        <span style={{fontWeight:'bolder'}}>TermsOfDelivery :</span> {detailToShow !== null && detailToShow.TermsOfDelivery}
                        </div>
                        <div   className="">
                        <span style={{fontWeight:'bolder'}}>OrderAccount :</span> {detailToShow !== null && detailToShow.facture.OrderAccount}
                        </div>
                        <div   className="">
                        <span style={{fontWeight:'bolder'}}>Compte client de facturation :</span> {detailToShow !== null && detailToShow.facture.InvoiceName}
                        </div>
                        <div   className="">
                        <span style={{fontWeight:'bolder'}}>SupplierAddress :</span> {detailToShow !== null && detailToShow.facture.SupplierAddress}
                        </div>
                        <div   className="">
                        <span style={{fontWeight:'bolder'}}>SupplierName :</span> {detailToShow !== null && detailToShow.facture.SupplierName}
                        </div>
                        
                        <div   className="">
                        <span style={{fontWeight:'bolder'}}>AmountInclVAT :</span> {detailToShow !== null && detailToShow.facture.AmountInclVAT}
                        </div>
                        <div   className="">
                        <span style={{fontWeight:'bolder'}}>Currency :</span> {detailToShow !== null && detailToShow.facture.Currency}
                        </div>
                        <div   className="">
                        <span style={{fontWeight:'bolder'}}>Votre référence :</span> {detailToShow !== null && detailToShow.facture.CustomerReference}
                        </div>
                        <div   className="">
                        <span style={{fontWeight:'bolder'}}>Commande n° :</span> {detailToShow !== null && detailToShow.facture.SONumber}
                        </div>
                        <div   className="">
                        <span style={{fontWeight:'bolder'}}>Commande du :</span> {detailToShow !== null && detailToShow.facture.SODate}
                        </div>
                        <div   className="">
                        <span style={{fontWeight:'bolder'}}>Filename :</span> {detailToShow !== null && detailToShow.facture.filename}
                        </div>
                        
                        <div   className="">
                        <span style={{fontWeight:'bolder'}}>Articles :</span> 
                        </div>
                       {
                          detailToShow !== null && detailToShow.facture.invoic_Lines && detailToShow.facture.invoic_Lines.length>0 && detailToShow.facture.invoic_Lines.map((item) => {
                           return <div   className="">
                            <div   className="">
                            <span style={{fontWeight:'bolder'}}>*Primary :</span> {item?.Primary  }
                            </div>
                            {item.Secundary && <div   className="">
                            <span style={{fontWeight:'bolder'}}>**Secundary :</span> {item?.Secundary  }
                            </div>}
                        </div>
                          })  
                        }  */}

                    </div>

                  </div>
              </div>
          </Modal>
          <Snackbar
              open={openToast.isOpenToast}
              autoHideDuration={6000}
              //onClose={()=>setOpenToast({...openToast, isOpenToast:false})}
              message={openToast.txt}
              action={<React.Fragment>
                  <IconButton
                      size="small"
                      aria-label="fermer"
                      color="inherit"
                      onClick={(event, reason) => {
                          if (reason === 'clickaway') {
                              return;
                          }
                          setOpenToast(false);
                      }}
                  >
                      <CloseIcon fontSize="small" />
                  </IconButton>
              </React.Fragment>}
          />
      </div>
          
      </LoadingOverlay>
  )
};
