import React, { ChangeEvent, useState } from "react"
import { useTranslation } from 'react-i18next';
import { Language } from '../../services/i18n/Language';
import ls from 'local-storage'

const Lang = ({lsi18n}) => {
    const { i18n } = useTranslation();

    const [lang, setLang] = useState(lsi18n);
    // const [lang, setLang] = useState(i18n.language as Language);

    let changeLanguage = (event) => {

        let language = event.target.value;
        switch (language) {
            case Language.DE:
                setLang(Language.DE);
                i18n.changeLanguage(Language.DE,async ()=>{
                    await ls.set('lsi18n', Language.DE);
                });
                break;
            case Language.NL:
                setLang(Language.NL);
                i18n.changeLanguage(Language.NL, async ()=>{
                    await ls.set('lsi18n', Language.NL);
                });
                break;
            case Language.EN:
                setLang(Language.EN);
                i18n.changeLanguage(Language.EN, async ()=>{
                    await ls.set('lsi18n', Language.EN);
                });
                break;
            case Language.FR:
                setLang(Language.FR);
                i18n.changeLanguage(Language.FR, async ()=>{
                    await ls.set('lsi18n', Language.FR);
                });
                break;

            default:
                setLang(Language.FR);
                i18n.changeLanguage(Language.FR, async ()=>{
                    await ls.set('lsi18n', Language.FR);
                });
                break;
        }
    }
 
    return (
        <div>
            <div>
                <select style={{width:'100%'}} value={lang} name="language" onChange={changeLanguage}>
                    <option value={Language.FR}>FR</option>
                    <option value={Language.EN}>EN</option>
                    <option value={Language.NL}>NL</option>
                    <option value={Language.DE}>DE</option>
                </select>
            </div>
        </div>
    )
}
 
export default Lang;