import React, { createContext, useState} from 'react'
import Header from "../Header";
import Menu from "./Menu";
import {Centredesadv_z_colis} from "./Centredesadv_z_colis";
import Footer from "../Footer";



function Z() {

    return (
        <div className="wrapper">
            <Header/>
            <Menu/>
            <Centredesadv_z_colis  />
            <Footer/>
        </div>
    );
}

export default Z;
