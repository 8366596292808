import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import Button from "@mui/material/Button";
import {Alert} from "../Authentication/index";
import SaveIcon from '@mui/icons-material/Save';
import {helpers} from "../../services/api/helpers";
import ls from "local-storage";
import Snackbar from "@mui/material/Snackbar/Snackbar";
import LoadingOverlay from "react-loading-overlay";
import DataTable from 'react-data-table-component';
import 'react-data-table-component-extensions/dist/index.css';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import { useLocation, useNavigate } from "react-router-dom"
import Toll from '@mui/icons-material/Toll';
import { useTranslation } from "react-i18next";
import { purple } from "@mui/material/colors";
import moment from "moment";
import _ from "lodash";
 import Troubleshoot from '@mui/icons-material/Troubleshoot';
import { ElectricalServices } from "@mui/icons-material";
import {Outsidecomponenti18n} from "../shared/Outsidecomponenti18n";
// let lacommande = ls.get('commande.groupe');
let isAdmin = ls.get('xxxx');

let columns = [];
if(isAdmin === "AAAA" ) {
     columns = ((clickHandler, onClickButtonlist) => [
    {
        name: Outsidecomponenti18n(`commande.column.commandenumero`),
        selector: row => row.SONumber,
        wrap:true,
        sortable:true,
        cell: (row) => <div style={{ }}><span style={{color:'green'}}>{row.SONumber}</span></div>
    },
    {
        name: Outsidecomponenti18n(`commande.column.commandedu`),
        selector: row => row.ordrsp.SODate,
        wrap:true,
        sortable:true,
        cell: (row) => <div style={{ }}>{row.ordrsp.SODate}</div>
    },
    {
        name: Outsidecomponenti18n(`commande.column.compteclient`),
        //selector: row => row.ordrsp.DeliveryAccount,
        selector: row => row.ordrsp.OrderAccount,
        wrap:true,
        sortable:true,
        hover:'zeze'
    },
    {
        name: Outsidecomponenti18n(`commande.column.votrereference`),
        selector: row => row.ordrsp.CustomerReference,
        wrap:true,
        sortable:true,
    },
    // {
    //     name: 'N° client de livraison',
    //     selector: row => row.ordrsp.DeliveryAccount,
    //     wrap:true,
    //     sortable:true,
    // },
    {
        name: Outsidecomponenti18n(`commande.column.numeroclientfacturation`),
        selector: row => row.ordrsp.InvoiceAccount,
        wrap:true,
        sortable:true,
    },
    // {
    //     name: "Note d'envoi",
    //     selector: row => row.ordrsp.PackingSlipId,
    //     wrap:true,
    //     sortable:true,
    // },
    {
        name: Outsidecomponenti18n(`commande.column.detailsdelacommande`),
        wrap:true,
        button: true,
        cell: (row) => {
            
            return<Link to="/dashboard/commande/ordrspline"  className="link">
                <div
                style={{ }}><span style={{paddingTop:4, paddingBottom:4, paddingLeft:6, paddingRight:6, backgroundColor:'#75ff42', borderRadius:10}}>{row?.ordrsp.ordrsp_Lines.length}</span><Button className={"btn btn-success px-1"}
                onClick={async function (e){
                    if(isenroute(row) === 'passe'){
                        ls.set('commande.etat' , 'Livré');
                    }else if(row.SONumber && row.desadv && row.ordrsp){
                        ls.set('commande.etat' , 'Preparée');
                    }else{
                        ls.set('commande.etat' , 'En attente');
                    }
                    await ls.set('ordrsp_Lines', JSON.stringify(row.ordrsp.ordrsp_Lines));
                    if(row.groupe !== null)
                    await ls.set('commande.groupe', JSON.stringify(row.groupe));
                    else
                    await ls.set('commande.groupe', JSON.stringify({}));

                }} id={row.id} style={{fontSize:11}}>{Outsidecomponenti18n('commande.column.article')}{row?.ordrsp.ordrsp_Lines.length > 1 && "s"}</Button></div>
                </Link>},

    },
    {
        name: Outsidecomponenti18n(`commande.column.notedenvoi`),
        wrap:true,
        button: true,
        cell: (row) =>  {return row.desadv ?  <Link to="/dashboard/commande/desadv"  className="link">
                <div
                style={{ }}><span style={{paddingTop:4, paddingBottom:4, paddingLeft:6, paddingRight:6, backgroundColor:'#75ff42', borderRadius:10}}>
                    {row?.desadv ? row?.desadv.length : 0}</span><Button className={"btn btn-success px-1"}
                onClick={async function (e){
                    if(row?.desadv && row?.desadv.length>0)
                    await ls.set('desadv', JSON.stringify(row.desadv));

                }} id={row.id} style={{fontSize:11}}>{Outsidecomponenti18n('commande.column.livraison')}</Button></div>
                </Link>:
            <div
            style={{ }}><span style={{paddingTop:4, paddingBottom:4, paddingLeft:6, paddingRight:6, backgroundColor:'#75ff42', borderRadius:10}}>
                {row?.desadv ? row?.desadv.length : 0}</span><Button className={"btn btn-success px-1"}
           id={row.id} style={{fontSize:11}}>{Outsidecomponenti18n('commande.column.livraison')}</Button></div>
           
            },

    },
    {
       // name: 'donwload preuve de livraison',
        name: Outsidecomponenti18n(`commande.column.suivre`),
        wrap:true,
        button: true,
        cell: (row) => {
            if(isHasZAdl(row).is === true)
            return <div style={{ }}><div title="ADL" onClick={onClickButtonlist} id={row.id} style={{fontSize:11, color: 'blue'}}>*<Toll />*</div></div>
                else
                return <div style={{ }}><div title="No ADL" onClick={()=>null} id={row.id} style={{fontSize:11, color: 'gray'}}>*<Toll />*</div></div>

        },
     },
    {
        name: Outsidecomponenti18n(`commande.column.etatdelacommande`),
        wrap:true,
        button: true,
        allowOverflow:true,
        cell: (row) =>{
         //     return <div style={{  }}>* Commandé le : <span style={{color:'purple'}}>{row.ordrsp.SODate}</span> 
        // {row.desadv &&  row.desadv.map((desadv)=>{
        //     return <div>* Ordre de transport(desadv) <span style={{color:'green'}}>{desadv.MessageNumber}</span> du: {desadv.MessageDate}</div>
        // }) }</div>

            if(isenroute(row) === 'passe' && row.desadv && row.desadv.length>0 && isHasZ(row).is === true)
            return <div style={{  }}>{Outsidecomponenti18n(`commande.column.livre`)}</div> 
            if(row.desadv && row.desadv.length>0 && isHasZ(row).is === true)
            return <div style={{  }}>{Outsidecomponenti18n(`commande.column.enlivraison`)}</div> 
            else if(row.desadv && row.desadv.length>0 )
            return <div style={{  }}>{Outsidecomponenti18n(`commande.column.enattente`)}</div> 
            else if(row.ordrsp.SODate)
            return    <div style={{  }}>{Outsidecomponenti18n(`commande.column.recu`)}</div> 
            ;
      
    
    },
    }, 
    {
    name: Outsidecomponenti18n(`commande.column.groupe`),
    selector: row => {
        if(row.groupe && row.groupe.nom)
        return row.groupe.nom;
        else 
        return ""
    },
    sortable:true,
    wrap:true,
    style:{fontSize:16},
},
]);
}else{
    columns = ((clickHandler, onClickButtonlist) => [
        {
            name: Outsidecomponenti18n(`commande.column.commandenumero`),
            selector: row => row.SONumber,
            wrap:true,
            sortable:true,
            cell: (row) => <div style={{ }}><span style={{color:'green'}}>{row.SONumber}</span></div>
        },
        {
            name: Outsidecomponenti18n(`commande.column.commandedu`),
            selector: row => row.ordrsp.SODate,
            wrap:true,
            sortable:true,
            cell: (row) => <div style={{ }}>{row.ordrsp.SODate}</div>
        },
        {
            name: Outsidecomponenti18n(`commande.column.compteclient`),
            //selector: row => row.ordrsp.DeliveryAccount,
            selector: row => row.ordrsp.OrderAccount,
            wrap:true,
            sortable:true,
            hover:'zeze'
        },
        {
            name: Outsidecomponenti18n(`commande.column.votrereference`),
            selector: row => row.ordrsp.CustomerReference,
            wrap:true,
            sortable:true,
        },
        {
            name: Outsidecomponenti18n(`commande.column.numeroclientfacturation`),
            selector: row => row.ordrsp.InvoiceAccount,
            wrap:true,
            sortable:true,
        },
        {
            name: Outsidecomponenti18n(`commande.column.detailsdelacommande`),
            wrap:true,
            button: true,
            cell: (row) => {
                
                return<Link to="/dashboard/commande/ordrspline"  className="link">
                    <div
                    style={{ }}><span style={{paddingTop:4, paddingBottom:4, paddingLeft:6, paddingRight:6, backgroundColor:'#75ff42', borderRadius:10}}>{row?.ordrsp.ordrsp_Lines.length}</span><Button className={"btn btn-success px-1"}
                    onClick={async function (e){
                        if(isenroute(row) === 'passe'){
                            ls.set('commande.etat' , 'Livré');
                        }else if(row.SONumber && row.desadv && row.ordrsp){
                            ls.set('commande.etat' , 'Preparée');
                        }else{
                            ls.set('commande.etat' , 'En attente');
                        }
                        await ls.set('ordrsp_Lines', JSON.stringify(row.ordrsp.ordrsp_Lines));
                        if(row.groupe !== null)
                        await ls.set('commande.groupe', JSON.stringify(row.groupe));
                        else
                        await ls.set('commande.groupe', JSON.stringify({}));
    
                    }} id={row.id} style={{fontSize:11}}>{Outsidecomponenti18n('commande.column.article')}{row?.ordrsp.ordrsp_Lines.length > 1 && "s"}</Button></div>
                    </Link>},
    
        },
        {
            name: Outsidecomponenti18n(`commande.column.suivre`),
            wrap:true,
            button: true,
            cell: (row) => {
            if(isHasZAdl(row).is === true)
            return <div style={{ }}><div title="ADL" onClick={onClickButtonlist} id={row.id} style={{fontSize:11, color: 'blue'}}>*<Toll />*</div></div>
                else
                return <div style={{ }}><div title="No ADL" onClick={()=>null} id={row.id} style={{fontSize:11, color: 'gray'}}>*<Toll />*</div></div>

        },
        },
        {
            name: Outsidecomponenti18n(`commande.column.etatdelacommande`),
            wrap:true,
            button: true,
            allowOverflow:true,
            cell: (row) =>{
                //     return <div style={{  }}>* Commandé le : <span style={{color:'purple'}}>{row.ordrsp.SODate}</span> 
               // {row.desadv &&  row.desadv.map((desadv)=>{
               //     return <div>* Ordre de transport(desadv) <span style={{color:'green'}}>{desadv.MessageNumber}</span> du: {desadv.MessageDate}</div>
               // }) }</div>
                   if(isenroute(row) === 'passe' && row.desadv && row.desadv.length>0 && isHasZ(row).is === true)
                   return <div style={{  }}>{Outsidecomponenti18n(`commande.column.livre`)}</div> 
                   if(row.desadv && row.desadv.length>0 && isHasZ(row).is === true)
                   return <div style={{  }}>{Outsidecomponenti18n(`commande.column.enlivraison`)}</div> 
                   else if(row.desadv && row.desadv.length>0 )
                   return <div style={{  }}>{Outsidecomponenti18n(`commande.column.enattente`)}</div> 
                   else if(row.ordrsp.SODate)
                   return    <div style={{  }}>{Outsidecomponenti18n(`commande.column.recu`)}</div> 
                   ;
           },
        }, 
        {
            name: Outsidecomponenti18n(`commande.column.groupe`),
            selector: row => {
            if(row.groupe && row.groupe.nom)
            return row.groupe.nom;
            else 
            return ""
        },
        sortable:true,
        wrap:true,
        style:{fontSize:16},
    },
    ]);
}


const isHasZ = (val) => {
    if(val.desadv){
         const retour = _.find(val.desadv, 'z');

         if(retour){
            const retour1 =  _.filter(retour.z, function(o) { return o.MessageNumber == retour.PackingSlipIds; });
            if(retour1){
                return {is:true, isHasZ: retour, jsonDn: retour1};
            }
         }
    }
    return {is:false};
};

const isHasZAdl = (val) => {
    if(val.ordrsp && isHasZ(val).is && isHasZ(val).jsonDn[0].best_fichier_z.CarrierId === "ADL"){
        return {is:true};
    }
    return {is:false};
};

function isenroute(item){
    if(item.ordrsp && isHasZ(item).is ){
        if(moment(new Date()).format("yyyyMMDD") >= moment(isHasZ(item).jsonDn[0].best_fichier_z.ClosedDateTime).format("yyyyMMDD")){
                return "passe";
        }else if(moment(new Date()).format("yyyyMMDD") === moment(isHasZ(item).jsonDn[0].best_fichier_z.ClosedDateTime).format("yyyyMMDD")){
                return "present"
        } else {
            return "futur"
        }
    }else{
        return "futur"
    }
};

export const Commande = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { i18n } = useTranslation();


    const [isshowmodal, setshowmodal] = useState(false);
    const [isLoadingSave, setLoadingSave] = useState(false);
    const [openSnack, setOpenSnack] = useState({
        isOpenNomError:false,
        txt:''});
    const [openToast, setOpenToast] = useState({isOpenToast:false, txt:''});
    const [tables, setTables] = React.useState([]);
    const [isLoading, setLoading] = React.useState(false);
    const [searchField, setSearchField] = useState("");
    const [detailToShow, setDetailToShow] = useState(null);

    const [bggreen, setbggreen] = useState("#74b842");
    //const [bgorange, setbgorange] = useState("rgba(39,79,141,0.55)");
    const [bgorange, setbgorange] = useState("orange");
    const [bgred, setbgred] = useState("#357cac");
    const [bggray, setbggray] = useState("#b3bccb");
    const [bgpurple, setbgpurple] = useState("#74b842");
    const [istimeline, setIstimeline] = useState('datatable');
    const [hkey, setHkey] = useState(undefined);
    const [visible, setVisible] = useState(10);
 
    let changeFunciton = (event) => {
        let lafunc = event.target.value;
        switch (lafunc) {
            case 'datatable':
                setIstimeline('datatable');
                break;
            case 'timeline':
            default:
                setIstimeline('timeline');
                break;
        }
    }


    const goToInventTable = (val) => {
        const from =  `/dashboard/iventtable/${val.target.id}`;
        navigate(from, { replace: true })
    };

    

    useEffect(()=>{

        const filteredTables = tables && tables.filter(
            item => {
                return (
                    item.nom !== null && item.nom
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                    item.adresse !== null && item.adresse
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                    item.commentaire !== null && item.commentaire
                        .toLowerCase()
                        .includes(searchField.toLowerCase())
                );
            }
        );
        if(searchField == '')
            getCommandes().catch(console.error);
        if(filteredTables !== undefined)
       setTables(filteredTables);
    },[searchField]);

    function rechercheindb(){
        if(searchField != ''){
            return;
        }
        return;

    }



    const getCommandes = async () => {
        if(isAdmin === "AAAA")
        helpers
            .getAllCommandesEtEtat()
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    setTables( r.data);
                }
            })
            else 
                helpers
            .getAllCommandesEtEtattoken()
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    setTables( r.data);
                }
            })
    };



    const getHkey = async () => {
        helpers
            .getHkey()
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    setHkey( r.data);
                }
            })
    };



    useEffect(()=>{
        setLoading(true);
        getCommandes().catch(console.error);
        getHkey().catch(console.error);
    },[]);

    

    const selectProps = { indeterminate: isIndeterminate => isIndeterminate };

    
    function Formatetat(item){
        if(isenroute(item) == "present" || isenroute(item) == "passe"){
            return {"what":"z", "message":  t('commande.expedie')}
        }
        else if(item.ordrsp && !item.desadv && !isHasZ(item).is && !item.invoic){
            return {"what":"ordrsp", "message":  t('commande.enattenteordrelivraison')}

        }else if(item.ordrsp && item.desadv && !isHasZ(item).is && !item.invoic) {
            return {"what":"desadv", "message": t('commande.preparepourenlevement')}
        }
    };




    function suivrelivraison(item){
        const url = `https://track.georeference.fr/pro/applications/delivery/?key=e0fdc6ae51b423f02ff3b600ae7eb825&prompt_placeholder=Numero%20de%20commande%20&panel_align=br&color=80BA27&panel_scale=big&map=roadmap`;
        window.open(url, "_blank");
    
    }


    function preuvelivraisontimeline(item){
        if(item.invoic){
             if(hkey != undefined){
              const url = `https://dojj533egh.execute-api.eu-west-3.amazonaws.com/v1/${hashkey[0].accountID}/delivery-doc/${item.SONumber}?hash=${hashkey[0].hash}`;
              window.open(url, "_blank");
             }
          }
    }
  
          
    const onClickButtonlist = (item) => {
        if(hkey != undefined){
          //  const url = `https://dojj533egh.execute-api.eu-west-3.amazonaws.com/v1/${hashkey[0].accountID}/delivery-doc/${item.SONumber}?hash=${hashkey[0].hash}`;
            const url = `https://track.georeference.fr/pro/applications/delivery/?key=e0fdc6ae51b423f02ff3b600ae7eb825&prompt_placeholder=Numero%20de%20commande%20&panel_align=br&color=80BA27&panel_scale=big&map=roadmap`;
            window.open(url, "_blank");
          }
    };
    

              


    const showMoreItems = () => {
        setVisible((prevValue) => prevValue + 10);
      };

  return (
      <LoadingOverlay
          active={isLoading}
          spinner
          text='Chargement...'
      >
      <div className="content-wrapper">
        <div className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6" style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                    <h1 className="m-0 text-dark">{t('commande.mescommandes')}</h1>
                        <div style={{width:150, marginLeft:20}}>      
                        <div>
                            <div>
                                <select style={{width:'100%'}} value={istimeline} name="selectcommandtodisplay" onChange={changeFunciton}>
                                    <option value={'datatable'}>{t('commande.datatable')}</option>
                                    <option value={'timeline'}>{t('commande.timeline')}</option>
                                </select>
                            </div>
                        </div>
                        </div>
                    </div>{/* /.col */}
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item">
                                    {t('commande.commande')}
                            </li>
                            <li className="breadcrumb-item active">
                                <Link to="/dashboard"  className="link">
                                myGlobalNet
                                </Link>
                            </li>
                        </ol>
                    </div>{/* /.col */}
                </div>{/* /.row */}
            </div>{/* /.container-fluid */}
        </div>

          {/*le datatable*/}
          <div className="card">
          {/* !"isTimeline" */}
             { istimeline === 'datatable' ?
             <div className="card-body">
                  <Paper
                      component="form"
                      sx={{ p: '2px 2px', display: 'flex', alignItems: 'center', width: 200 }}
                  >
                      <InputBase
                          sx={{ ml: 1, flex: 1 }}
                          placeholder="Rechercher"
                          inputProps={{ 'aria-label': 'Rechercher' }}
                          onChange={(e)=>{
                              e.preventDefault();
                              setSearchField(e.target.value);
                          }}
                          value={searchField}
                      />
                      <IconButton  onClick={() => {
                          rechercheindb()
                      }} type="button" sx={{ p: '5px' }} aria-label="search">
                          <SearchIcon />
                      </IconButton>
                  </Paper>


                  <DataTable
                      pagination
                     // selectableRowsComponent={Checkbox}
                     selectableRowsComponentProps={selectProps}
                     // sortIcon={sortIcon}
                     dense
                     //{...props}
                     //title="Administration"
                     // theme={'dark'}
                     //  columns={columns}
                     columns={ columns(goToInventTable, onClickButtonlist) }

                      data={tables}
                      striped
                      highlightOnHover
                      persistTableHead
                      fixedHeader={false}
                      progressPending={false}
                      noDataComponent={"Aucune données à afficher"}
                      onRowClicked={(row, event) => {console.log('row clicked', row);
                      setDetailToShow(row);
                          setshowmodal(true);
                      }}
                      paginationComponentOptions={{ rowsPerPageText: t('commande.ligneparpage'), rangeSeparatorText: t('commande.of'), noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: t('commande.all') }}
                  />


              </div>
            :  
            <div>
                { tables.length==0 &&  <div   style= {{display: "flex", alignItems: "center", justifyContent: "center", width: "100%",  fontSize: 15, color: "#8f8f8f", padding: 25, marginTop: 20, marginBottom: 20}}  >
                    {t('aucunedonne')}
                </div>}
                {/* listercommandes.map */}
                { tables.length > 0 && tables?.slice(0, visible).map((item, i)=>{
                    return <div  className="pointer-cl" style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginTop: 30}}>
                 
                 <div   style={{display:"flex", flexDirection: "column", alignItems: "center", marginTop: 10, justifyContent: "center"}}>
                     <div style={{fontWeight: "bold", fontSize: 15, color: "#21385A", marginBottom: 7 }}>
                    {t('commande2')}
                    </div>
                    <div  style={{textAlign: "center", fontSize: 10}}>
                    {item?.ordrsp?.SONumber} du {item?.ordrsp?.SODate}
                    </div>
                    {item?.ordrsp?.SalesId != null ? (item?.ordrsp?.SalesId).toUpperCase() : ""}
                        <div onClick={()=>{setshowmodal(true); setDetailToShow(item)}} style={{backgroundColor:bggreen, borderRadius: 50, width:50, height: 50, display: "flex", alignItems: "center", justifyContent: 'center'}}>
                            <i className="fas fa-check" style={{color: "#fff"}}></i></div>
                        <div style={{ textAlign: "center"}}>
                        {t('commande.commandevalide').toUpperCase()}
                        </div>
                        {/* fontweight :800 fa ts bold raha vita */}
                        {item.ordrsp.SalesBalance ? 
                        <div  style={{textAlign: "center", fontSize: 10, fontWeight: '800', color:"#1fb820"}}>
                        {t('montant1')} : {item.ordrsp.SalesBalance}
                        </div>
                        :
                        <div  style={{textAlign: "center", fontSize: 10, fontWeight: '800', color:"#1fb820"}}>
                        ttc : {item.ordrsp.AmountInclVAT}
                        </div>
                        }
                         <div  style={{textAlign: "center", fontSize: 10}}>
                         {t('client')} {item?.ordrsp?.OrderAccount}
                        </div>
                    </div>


                    <div   style={{backgroundColor:bggreen, width: "20%", height: 3}}></div>
                   

                    <div    style={{display:"flex", flexDirection: "column", alignItems: "center", marginTop: 10}}>
                        <div  style={{fontWeight: "bold", fontSize: 15, color:" #21385A", marginBottom: 7}}>
                        {t('etat')}
                        </div>
                       
                         { item.SONumber } 
                        <div onClick={()=>{setshowmodal(true); setDetailToShow(item)}}  style={{backgroundColor: item?.invoic === 'invoic' ||  Formatetat(item)?.what === 'z' ? bggreen : Formatetat(item)?.what === 'desadv' ? bgorange : bggray, borderRadius: 50, width:50, height: 50, display: "flex", alignItems: "center", justifyContent: "center"}}>
                            {Formatetat(item)?.what === 'ordrsp' ?
                             <div style={{fontSize: 18, color:"#fff", fontWeight: "bold"}}>
                             X
                         </div>
                            :
                            <i className="fas fa-check" style={{color: "#fff"}}></i>}
                            </div>
                        <div style={{textAlign: "center", fontSize:13}}>
                        {Formatetat(item).message}
                        { Formatetat(item)?.what === 'desadv' && item.desadv.map(desadv=> <div style={{fontSize:11}}><span style={{color: "purple"}}>{desadv.MessageNumber}</span>{ t('commande.le') + desadv.MessageDate }</div>)}
                        </div>
                    </div>
                    {/* fin second */}


                    {!isHasZ(item).is  && <div   style={{display: "flex", flexDirection: "row", alignItems: "center", width: "20%", height: 3}}>
                        <div   style={{backgroundColor: bggreen, width: "50%", height: 3}}></div>
                        <div  style={{backgroundColor: bggray, width: "50%", height: 3}}></div>
                    </div>}
                    { isHasZ(item).is  && <div style={{backgroundColor: bggreen, width: "20%", height: 3}}></div>}


                    <div  onClick={()=>{setshowmodal(true); setDetailToShow(item)}}   style={{display:"flex", flexDirection: "column",alignItems: "center", marginTop: 10 }}>
                       <div  style={{fontWeight: "bold", fontSize: 15, color: "#21385A", marginBottom: 7}}>
                            {t('livraison')}
                        </div>
                        {isHasZ(item).is && <div  style={{textAlign: "center", fontSize: 10, color:"#44b1ac"}}>
                        <span style={{color: "purple"}}>{ isHasZ(item).jsonDn[0].ContainerId1}</span>{ t('commande.du') +  isHasZ(item).jsonDn[0].best_fichier_z.ClosedDateTime }
                        </div>}
                        {item.SONumber}
                        <div  style={{backgroundColor: isenroute(item) == "futur" ? bggray : isenroute(item) == "present" ? bgorange : bggreen, borderRadius: 50, width:50, height: 50, display: "flex", alignItems: "center", justifyContent: "center"}}>
                           {(isenroute(item) == "present" || isenroute(item) == "passe") && <i  className="fas fa-check" style={{color: "#ffffff"}}></i>}
                        {isenroute(item) == "futur" && <div style={{fontSize: 18, color:"#fff", fontWeight: "bold"}}>
                            X
                        </div>}
                        </div>
                        {(isenroute(item) == "present" || isenroute(item) == "passe")  ? 
                        <div style={{textAlign: "center", fontSize:12}}>
                        <div style={{}}>
                        {t('enroute')}
                        </div>
                        <div style={{}}>
                        {isHasZ(item).isHasZ.MessageNumber}
                        </div>
                        <div style={{}}>
                        {t('commande.dest')}{isHasZ(item).jsonDn[0].Destination}
                        </div>
                        
                        </div>
                        :
                        <div style={{textAlign: "center", fontSize:13}}>
                        {t('enattentedateexpedition')}
                        </div> }
                    </div>

                    {isenroute(item) == "futur" && <div style={{backgroundColor:'gray', width: "20%", height: 3}}></div>}
                    {isenroute(item) == "present"  && <div   style={{display: "flex", flexDirection: "row", alignItems: "center", width: "20%", height: 3}}>
                        <div   style={{backgroundColor:'green', width: "50%", height: 3}}></div>
                        <div  style={{backgroundColor:'gray', width: "50%", height: 3}}>
                        </div>
                    </div>}                
                    {isenroute(item) == "passe" && <div style={{backgroundColor:'green', width: "20%", height: 3}}></div>}



                    <div   style={{display:"flex", flexDirection: "column", alignItems: "center", marginTop: 10}}>
                            <div   style={{fontWeight: "bold", fontSize: 15, color: "#21385A", marginBottom: 7}}>
                                {t('suivi')}
                            </div>
                            { item.SONumber }
                          
                            <div onClick={()=>{setshowmodal(true); setDetailToShow(item)}}  style={{backgroundColor:(isenroute(item) == "present" || isenroute(item) == "passe") ? bggreen : bggray, borderRadius: 50, width:50, height: 50, display: "flex", alignItems: "center", justifyContent: "center"}}><i className="fas fa-truck" style={{color: "#fff"}}></i></div>
                            {isHasZ(item).is && <div style={{fontSize:10}}>
                            {isHasZ(item).jsonDn[0].WMSShipmentId}
                            </div>}
                            <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginTop: 5}}>
                    {/* <!--          suivi quand la livraison n'est pas effectué,-->
                    <!--          console de géolocalisation, desactivév apres--> */}
                            <button onClick={()=>suivrelivraison("item")}     style={{backgroundColor:item.invoic? bggray: bgred, borderWidth: 0, color: "white", borderRadius: 4, padding: 2, display:"flex", alignItems: "center", justifyContent: "center", fontSize: 10}}>
                              {t('suivre')}
                            </button>
                            <div onClick={()=>preuvelivraisontimeline("item")}    style={{backgroundColor:item.invoic ? bgpurple: bggray, marginLeft:5, borderRadius: 4, paddingTop: 2, paddingBottom: 2, paddingLeft: 2, paddingRight: 2,  color:"white", display:"flex", alignItems: "center", justifyContent: "center", fontSize: 10}}>
                            {t('telecharger')}
                            </div>
                            </div>
                        </div>


                        {/* <!--      progress3--> */}
                        {item.invoic && <div  style={{backgroundColor:bggreen, width: "20%", height: 3}}></div>}
                        {!item.invoic && <div  style={{backgroundColor:bggray, width: "20%", height: 3}}></div>}



                        <div   style={{display:"flex", flexDirection: "column", alignItems: "center", marginTop: 10}}>
                            <div    style={{fontWeight: "bold", fontSize: 15, color: "#21385A", marginBottom: 7}}>
                            {t('facture1')}
                            </div>

                            {/* item.dateisfacturation to fromat */}
                            {item?.invoic && <div  style={{texAalign: "center", fontSize: 10, color:"#44b1ac"}}>
                            {item?.invoic?.SONumber} {t('dcommande.du')} {item.invoic.MessageDate}
                            </div>}

                            {item.invoic &&  item.invoic.MessageNumber}
                            <div onClick={()=>{setshowmodal(true); setDetailToShow(item)}}  style={{backgroundColor:item.invoic ? bggreen : bgorange, borderRadius: 50, width:50, height: 50, display: "flex", alignItems: "center", justifyContent: "center"}}><i className="fas fa-file-invoice" style={{color: "#fff"}}></i></div>
                            {item.invoic
                             ? 
                            <div style={{textAlign: "center"}}>
                                {t('commandefacture')}
                            </div>
                            :
                            <div style={{textAlign: "center"}}>
                            {t('commande.commandenonfacture')}
                            </div>
                            }
                            
                            {item.invoic && <div style={{textAlign: "center", fontSize:13}}>
                                { item.invoic.InvoiceAccount}
                            </div>}

                        </div>


                    </div>
                })}      
            
                  {visible < tables.length ?  <button style={{backgroundColor:'green', color: 'white', padding:15, marginBottom:20, borderRadius:10, marginRight:20}} onClick={showMoreItems} type="button" className="close" data-dismiss="modal" aria-label={t('menu.afficherplus')}>
                  {t('menu.afficherplus')} 
                  <Troubleshoot fontSize="small" />

                          </button>
                        :
                        <div style={{backgroundColor:'gray', color: 'white', padding:15, marginBottom:20, borderRadius:10, marginRight:20}}    className="close" data-dismiss="modal" aria-label={t('menu.afficherplus')}>
                  {t('menu.afficherplus')} 
                  <Troubleshoot fontSize="small" />

                          </div>  
                        }
            </div>
            }
              {/*fin body*/}
          </div>

          
          <Modal
              open={isshowmodal}
              onClose={()=>setshowmodal(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              style={{overflow: 'scroll' }}

          >
              <div className="modal-dialog ">
                  <div className="modal-content">
                      <div className="modal-header">
                          <h4 className="modal-title">{t('commande.detailsdelacommande')}</h4>
                          <button onClick={()=>setshowmodal(false)} type="button" className="close" data-dismiss="modal" aria-label="Fermer">
                              <span aria-hidden="true">&times;</span>
                          </button>
                      </div>

                      <div   className="modal-body">
                      <div   className="">
                     <span style={{fontWeight:'bolder'}}>{t('commande.column.commandenumero')} :</span> {detailToShow !== null && detailToShow?.ordrsp?.SONumber}
                        </div>
                        <div   className="">
                        <span style={{fontWeight:'bolder'}}>{t('commande.column.commandedu')} :</span> {detailToShow !== null && detailToShow?.ordrsp?.SODate}
                        </div>                    
                        <div   className="">
                        <span style={{fontWeight:'bolder'}}>{t('commande.numeroclientlivraison')} :</span> {(detailToShow !== null && detailToShow?.ordrsp?.DeliveryAccount) + " - " + (detailToShow !== null && detailToShow?.ordrsp?.DeliveryAddress) }
                        </div>
                        {/* <div   className="">
                        <span style={{fontWeight:'bolder'}}>Adresse de livraison :</span> {detailToShow !== null && detailToShow?.ordrsp?.DeliveryAddress}
                        </div> */}
                        <div   className="">
                        <span style={{fontWeight:'bolder'}}>{t('commande.column.numeroclientfacturation')} :</span> {(detailToShow !== null && detailToShow?.ordrsp?.InvoiceAccount) + " - " + (detailToShow !== null && detailToShow?.ordrsp?.InvoiceAddress)}
                        </div>   
                        {/* <div   className="">
                        <span style={{fontWeight:'bolder'}}>Adresse de facturation :</span> {detailToShow !== null && detailToShow?.ordrsp?.InvoiceAddress}
                        </div> */}
                        <div   className="">
                        <span style={{fontWeight:'bolder'}}>{t('commande.montantht')} :</span> {detailToShow !== null && detailToShow?.ordrsp?.AmountExVAT}
                        </div>
                        <div   className="">
                        <span style={{fontWeight:'bolder'}}>{t('commande.votrereference')} :</span> {detailToShow !== null && detailToShow?.ordrsp?.CustomerReference}
                        </div>
                        {/* <div   className="">
                        <span style={{fontWeight:'bolder'}}>Compte client :</span> {detailToShow !== null && detailToShow?.ordrsp?.DeliveryName}
                        </div>
                        <div   className="">
                        <span style={{fontWeight:'bolder'}}>MessageDate :</span> {detailToShow !== null && detailToShow?.ordrsp?.MessageDate}
                        </div>
                        <div   className="">
                        <span style={{fontWeight:'bolder'}}>Livraison effectué par :</span> {detailToShow !== null && detailToShow?.ordrsp?.ModeOfDelivery}
                        </div>
                        <div   className="">
                        <span style={{fontWeight:'bolder'}}>TermsOfDelivery :</span> {detailToShow !== null && detailToShow?.ordrsp?.TermsOfDelivery}
                        </div>
                        <div   className="">
                        <span style={{fontWeight:'bolder'}}>OrderAccount :</span> {detailToShow !== null && detailToShow?.ordrsp?.OrderAccount}
                        </div>
                        <div   className="">
                        <span style={{fontWeight:'bolder'}}>Compte client de facturation :</span> {detailToShow !== null && detailToShow?.ordrsp?.InvoiceName}
                        </div>

                        <div   className="">
                        <span style={{fontWeight:'bolder'}}>SupplierAddress :</span> {detailToShow !== null && detailToShow?.ordrsp?.SupplierAddress}
                        </div>
                        <div   className="">
                        <span style={{fontWeight:'bolder'}}>SupplierName :</span> {detailToShow !== null && detailToShow?.ordrsp?.SupplierName}
                        </div>
                        <div   className="">
                        <span style={{fontWeight:'bolder'}}>AmountInclVAT :</span> {detailToShow !== null && detailToShow?.ordrsp?.AmountInclVAT}
                        </div>
                        <div   className="">
                        <span style={{fontWeight:'bolder'}}>Currency :</span> {detailToShow !== null && detailToShow?.ordrsp?.Currency}
                        </div>
                        <div   className="">
                        <span style={{fontWeight:'bolder'}}>MessageNumber :</span> {detailToShow !== null && detailToShow?.ordrsp?.MessageNumber}
                        </div>
                        <div   className="">
                        <span style={{fontWeight:'bolder'}}>Filename :</span> {detailToShow !== null && detailToShow?.ordrsp?.filename}
                        </div>
                        <div style={{color:"purple"}}  className="">
                        <span style={{fontWeight:'bolder'}}>DESADV :</span>
                        </div>
                        {
                            detailToShow !== null && 
                            detailToShow?.desadv &&
                            detailToShow?.desadv.map((desadv)=>{
                                return  <div   className="">
                                    
                                    <div   className="">
                                    <span style={{fontWeight:'bolder'}}>MessageNumber :</span> {desadv.MessageNumber && desadv.MessageNumber}
                        </div>
                        <div   className="">
                        <span style={{fontWeight:'bolder'}}>N° client de livraison :</span> {desadv.DeliveryAccount && desadv.DeliveryAccount}
                        </div>
                        <div   className="">
                        <span style={{fontWeight:'bolder'}}>Adresse de livraison :</span> {desadv.DeliveryAddress && desadv.DeliveryAddress}
                        </div>
                        <div   className="">
                        <span style={{fontWeight:'bolder'}}>N° client de facturation :</span> {desadv.InvoiceAccount && desadv.InvoiceAccount}
                        </div>
                        <div   className="">
                        <span style={{fontWeight:'bolder'}}>Adresse de facturation :</span> {desadv.InvoiceAddress && desadv.InvoiceAddress}
                        </div>
                        <div   className="">
                        <span style={{fontWeight:'bolder'}}>Compte client :</span> {desadv.DeliveryName && desadv.DeliveryName}
                        </div>
                        <div   className="">
                        <span style={{fontWeight:'bolder'}}>Livraison effectué par :</span> {desadv.ModeOfDelivery && desadv.ModeOfDelivery}
                        </div>
                        <div   className="">
                        <span style={{fontWeight:'bolder'}}>TermsOfDelivery :</span> {desadv.TermsOfDelivery && desadv.TermsOfDelivery}
                        </div>
                        <div   className="">
                        <span style={{fontWeight:'bolder'}}>OrderAccount :</span> {desadv.OrderAccount && desadv.OrderAccount}
                        </div>
                       
                        <div   className="">
                        <span style={{fontWeight:'bolder'}}>Compte client de facturation :</span> {desadv.InvoiceName && desadv.InvoiceName}
                        </div>
                        <div   className="">
                        <span style={{fontWeight:'bolder'}}>SupplierName :</span> {desadv.SupplierName && desadv.SupplierName}
                        </div>
                        <div   className="">
                        <span style={{fontWeight:'bolder'}}>Votre référence :</span> {desadv.CustomerReference && desadv.CustomerReference}
                        </div>
                    
                        {
                            desadv.desadv_Lines &&
                            desadv.desadv_Lines.length > 0 &&
                            desadv.desadv_Lines.map((desadv_Lines)=>{
                                return    <div   className="">
                         <div   className="">
                         <span style={{fontWeight:'bolder'}}>Primary :</span> {desadv_Lines.Primary && desadv_Lines.Primary}
                        </div>
                                </div>
                            })
                        }

                        <div style={{color:"purple"}}  className="">
                        <span style={{fontWeight:'bolder'}}>Fichier Z :</span>
                        </div>
                         {
                            desadv.z && desadv.z.length > 0 &&
                            desadv.z.map((z)=>{
                              return  <div  className="">
                                 <div   className="">
                                 <span style={{fontWeight:'bolder'}}>WMSShipmentId :</span>  {z.WMSShipmentId && z.WMSShipmentId}
                                    </div>
                                <div   className="">
                                <span style={{fontWeight:'bolder'}}>CarrierConsigmentNumber :</span>  {z.CarrierConsigmentNumber && z.CarrierConsigmentNumber}
                                    </div>
                                    <div   className="">
                                    <span style={{fontWeight:'bolder'}}>CountryRegionId1 :</span>  {z.CountryRegionId1 && z.CountryRegionId1}
                                    </div>
                                    <div   className="">
                                    <span style={{fontWeight:'bolder'}}>ContainerId :</span>  {z.ContainerId1 && z.ContainerId1}
                                    </div>
                                    <div   className="">
                                    <span style={{fontWeight:'bolder'}}>DeliveryDate :</span>  {z.DeliveryDate && z.DeliveryDate}
                                    </div>
                                    <div   className="">
                                    <span style={{fontWeight:'bolder'}}>Destination :</span>  {z.Destination && z.Destination}
                                    </div>
                                    <div   className="">
                                    <span style={{fontWeight:'bolder'}}>DestinationName :</span>  {z.DestinationName && z.DestinationName}
                                    </div>
                                    <div   className="">
                                    <span style={{fontWeight:'bolder'}}>DlvDate :</span>  {z.DlvDate && z.DlvDate}
                                    </div>
                                    <div   className="">
                                    <span style={{fontWeight:'bolder'}}>DlvModeId :</span>  {z.DlvModeId && z.DlvModeId}
                                    </div>
                                    <div   className="">
                                    <span style={{fontWeight:'bolder'}}>DlvTerms :</span>  {z.DlvTerms && z.DlvTerms}
                                    </div>
                                    <div   className="">
                                    <span style={{fontWeight:'bolder'}}>PackingSlipIds :</span> {z.PackingSlipIds && z.PackingSlipIds}
                                    </div>
                                    <div   className="">
                                    <span style={{fontWeight:'bolder'}}>PackingSlipIdTransporter :</span>  {z.PackingSlipIdTransporter && z.PackingSlipIdTransporter}
                                    </div>
                                    <div   className="">
                                    <span style={{fontWeight:'bolder'}}>Street :</span> {z.Street && z.Street}
                                    </div>
                                    <div   className="">
                                    <span style={{fontWeight:'bolder'}}>ZipCodeId :</span>  {z.ZipCodeId && z.ZipCodeId}
                                    </div>


                                    <div   className="">
                                    <span style={{fontWeight:'bolder'}}>ContainerId :</span>  {z.best_fichier_z?.ContainerId && z.best_fichier_z?.ContainerId}
                                    </div>
                                    <div   className="">
                                    <span style={{fontWeight:'bolder'}}>InventSiteId :</span>  {z.best_fichier_z?.InventSiteId && z.best_fichier_z?.InventSiteId}
                                    </div>
                                    <div   className="">
                                    <span style={{fontWeight:'bolder'}}>ReferenceNumber :</span>  {z.best_fichier_z?.ReferenceNumber && z.best_fichier_z?.ReferenceNumber}
                                    </div>
                                    <div   className="">
                                    <span style={{fontWeight:'bolder'}}>TransmissionNumber :</span>  {z.best_fichier_z?.TransmissionNumber && z.best_fichier_z?.TransmissionNumber}
                                    </div>
                                    <div   className="">
                                    <span style={{fontWeight:'bolder'}}>ConsignorCity :</span>  {z.best_fichier_z?.ConsignorCity && z.best_fichier_z?.ConsignorCity}
                                    </div>
                                    <div   className="">
                                    <span style={{fontWeight:'bolder'}}>ConsignorName :</span>  {z.best_fichier_z?.ConsignorName && z.best_fichier_z?.ConsignorName}
                                    </div>
                                    <div   className="">
                                    <span style={{fontWeight:'bolder'}}>ConfigRecId :</span>  {z.best_fichier_z?.ConfigRecId && z.best_fichier_z?.ConfigRecId}
                                    </div>
                              </div>  
                            })
                         }
                         </div>
                            })
                        }*/}
                   
                    </div> 

                  </div>
              </div>
          </Modal>
          <Snackbar
              open={openToast.isOpenToast}
              autoHideDuration={6000}
              //onClose={()=>setOpenToast({...openToast, isOpenToast:false})}
              message={openToast.txt}
              action={<React.Fragment>
                  <IconButton
                      size="small"
                      aria-label="fermer"
                      color="inherit"
                      onClick={(event, reason) => {
                          if (reason === 'clickaway') {
                              return;
                          }
                          setOpenToast(false);
                      }}
                  >
                      <CloseIcon fontSize="small" />
                  </IconButton>
              </React.Fragment>}
          />
      </div>
          
      </LoadingOverlay>
  )
};
