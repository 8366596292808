import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from "@mui/material/Snackbar/Snackbar";
import LoadingOverlay from "react-loading-overlay";
import DataTable from 'react-data-table-component';
import 'react-data-table-component-extensions/dist/index.css';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import { useLocation, useNavigate } from "react-router-dom"
import Toll from '@mui/icons-material/Toll';
import ls from 'local-storage'
import Button from "@mui/material/Button";
let lacommande = ls.get('commande.groupe');
import { useTranslation } from "react-i18next";
import {Outsidecomponenti18n} from "../shared/Outsidecomponenti18n";


const columns = ((clickHandler, onClickButtonlist) => [
    {
        name: Outsidecomponenti18n(`commande.centredesadv.notedenvoi`),
        selector: row => row.MessageNumber,
        wrap:true,
        cell: (row) => <Link to="/dashboard/commande"  className="link">
                    <div  
                 style={{ }}>{row.MessageNumber}</div>
   
            </Link> },
    {
        name: Outsidecomponenti18n(`commande.centredesadv.commandenumero`),
        selector: row => row.SONumber,//message number du facture, car il y a plusieurs message number , ie , suivant files
        wrap:true,
        cell: (row) => <div  
                 style={{ }}>{row.SONumber}</div>
   
         },
    // {
    //     name: 'Groupe',
    //     selector: parsegroupe => {
    //         console.log('row', parsegroupe)
    //         //const parsegroupe = JSON.parse(row);
    //         if(parsegroupe && parsegroupe.nom)
    //         return parsegroupe.nom;
    //     },
    //     sortable:true,
    //     wrap:true,
    //     style:{fontSize:16},
    // },
    // {
    //     name: 'N° client de facturation',
    //     selector: row => row.InvoiceAccount,
    //     wrap:true,
    //     sortable:true,
    //     hover:'zeze'
    // },
    {
        name: Outsidecomponenti18n(`commande.centredesadv.compteclientdefacturation`),
        selector: row => row.InvoiceName,
        wrap:true,
        sortable:true,
        cell: (row) => <div 
        style={{ }}>{row.InvoiceName}</div>
    },
    {
        name: Outsidecomponenti18n(`commande.centredesadv.adressedelivraison`),
        selector: row => row.DeliveryAddress,
        wrap:true,
        sortable:true,
        cell: (row) => <div 
        style={{ }}>{row.DeliveryAddress}</div>
    },

    // {
    //     name: 'SupplierName',
    //     selector: row => row.SupplierName,
    //     wrap:true,
    //     sortable:true,
    //     cell: (row) => <div 
    //     style={{ }}>{row.SupplierName}</div>
    // },
    {
        name: Outsidecomponenti18n(`commande.centredesadv.du`),
        selector: row => row.MessageDate,
        wrap:true,
        sortable:true,
        cell: (row) => <div 
        style={{ }}>{moment(row.MessageDate).format('DD/MM/yyyy')}</div>
    },
    // {
    //     name: 'desadv line',
    //     wrap:true,
    //     button: true,
    //     cell: (row) =>  {return row.desadv ?  <Link to="/dashboard/commande/desadv"  className="link">
    //             <div
    //             style={{ }}><span style={{paddingTop:4, paddingBottom:4, paddingLeft:6, paddingRight:6, backgroundColor:'#75ff42', borderRadius:10}}>
    //                 {row?.desadv ? row?.desadv.length : 0}</span><Button className={"btn btn-success px-1"}
    //             onClick={async function (e){
    //                 if(row.desadv)
    //                 await ls.set('desadv', JSON.stringify(row.desadv));

    //             }} id={row.id} style={{fontSize:11}}>desadv</Button></div>
    //             </Link>:
    //         <div
    //         style={{ }}><span style={{paddingTop:4, paddingBottom:4, paddingLeft:6, paddingRight:6, backgroundColor:'#75ff42', borderRadius:10}}>
    //             {row?.desadv ? row?.desadv.length : 0}</span><Button className={"btn btn-success px-1"}
    //        id={row.id} style={{fontSize:11}}>desadv</Button></div>
           
    //         },

    // },
    // {
    //     name: 'Desadv line',
    //     wrap:true,
    //     button: true,
    //     cell: (row) =>  <Link to="/dashboard/commande/desadv/desadvline"  className="link">
    //             <div
    //             style={{ }}><span style={{paddingTop:4, paddingBottom:4, paddingLeft:6, paddingRight:6, backgroundColor:'#75ff42', borderRadius:10}}>{row?.desadv_Lines.length}</span>
    //             <Button className={"btn btn-success px-1"}
    //             onClick={async function (e){
    //                 await ls.set('desadv_Lines', JSON.stringify(row.desadv_Lines));
    //             }} id={row.id} style={{fontSize:11}}>Desadv lines</Button></div>
    //             </Link>,

    // },
    // {
    //     name: 'Fichier z',
    //     wrap:true,
    //     button: true,
    //     cell: (row) =>  <Link to="/dashboard/commande/desadv/z"  className="link">
    //             <div
    //             style={{ }}><span style={{paddingTop:4, paddingBottom:4, paddingLeft:6, paddingRight:6, backgroundColor:'#75ff42', borderRadius:10}}>{row?.z ? row?.z.length : 0 }</span>
    //             <Button className={"btn btn-success px-1"}
    //             onClick={async function (e){
    //                 await ls.set('z', JSON.stringify(row.z));
    //                 await ls.set('itemdesadv', JSON.stringify(row));
    //             }} id={row.id} style={{fontSize:11}}>fichier Z</Button></div>
    //             </Link>,

    // },

]);


export const Centredesadv = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { i18n } = useTranslation();

    const [isshowmodal, setshowmodal] = useState(false);
    const [openSnack, setOpenSnack] = useState({
        isOpenNomError:false,
        txt:''});
    const [openToast, setOpenToast] = useState({isOpenToast:false, txt:''});
    const [tables, setTables] = React.useState([]);
    const [isLoading, setLoading] = React.useState(false);
    const [searchField, setSearchField] = useState("");
    const [detailToShow, setDetailToShow] = useState(null);
    
    const goToFacture = (val) => {
        const from =  `/dashboard/facture`;
        navigate(from, { replace: true })
    };
    
    const onClickButtonlist = (val) => {
      
        console.log("ok")
    };
    
    

    useEffect(()=>{
        const filteredTables = tables && tables.filter(
            item => {
                return (
                    item.nom !== null && item.nom
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                    item.adresse !== null && item.adresse
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                    item.commentaire !== null && item.commentaire
                        .toLowerCase()
                        .includes(searchField.toLowerCase())
                );
            }
        );
        if(searchField == '')
        getdesadv().catch(console.error);
        if(filteredTables !== undefined)
       setTables(filteredTables);
    },[searchField]);

    function rechercheindb(){
        if(searchField != ''){
            return;
        }
        return;
    }


    const getdesadv = async () => {
        const desadv = await JSON.parse(await ls.get('desadv'));
        setTables(desadv);
        setLoading(false);
    };

    useEffect(()=>{
        setLoading(true);
        getdesadv().catch(console.error);
    },[]);


    const selectProps = { indeterminate: isIndeterminate => isIndeterminate };


  return (
      <LoadingOverlay
          active={isLoading}
          spinner
          text={Outsidecomponenti18n(`commande.centredesadv.chargement`)}
      >
      <div className="content-wrapper">
        <div className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0 text-dark">{Outsidecomponenti18n(`commande.centredesadv.notesdenvoi`)}</h1>
                    </div>{/* /.col */}
                    <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                <Link to="/dashboard/commande"  className="link">
                    {"<    " + Outsidecomponenti18n(`commande.centredesadv.retourauxcommandes`)}
                </Link>
                </ol>
                </div>
                    {/* <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item">
                                    Desadv
                            </li>
                        <li className="breadcrumb-item">
                                    <Link to="/dashboard/commande"  className="link">
                                    commande
                                </Link>
                            </li>
                         
                           
                            <li className="breadcrumb-item active">
                                <Link to="/dashboard"  className="link">
                                myGlobalNet
                                </Link>
                            </li>
                        </ol>
                    </div> */}
                </div>{/* /.row */}
            </div>{/* /.container-fluid */}
        </div>

          {/*le datatable*/}
          <div className="card">
              <div className="card-body">
                  <Paper
                      component="form"
                      sx={{ p: '2px 2px', display: 'flex', alignItems: 'center', width: 200 }}
                  >
                      <InputBase
                          sx={{ ml: 1, flex: 1 }}
                          placeholder={Outsidecomponenti18n(`commande.centredesadv.rechercher`)}
                          inputProps={{ 'aria-label': 'Rechercher' }}
                          onChange={(e)=>{
                              e.preventDefault();
                              setSearchField(e.target.value);
                          }}
                          value={searchField}
                      />
                      <IconButton  onClick={() => {
                          rechercheindb()
                      }} type="button" sx={{ p: '5px' }} aria-label="search">
                          <SearchIcon />
                      </IconButton>
                  </Paper>


                  <DataTable
                      pagination
                      selectableRowsComponentProps={selectProps}
                      dense                
                      columns={columns(goToFacture, onClickButtonlist)}
                      data={tables}
                      striped
                      highlightOnHover
                      persistTableHead
                      fixedHeader={false}
                      progressPending={false}
                      noDataComponent={Outsidecomponenti18n(`commande.centredesadv.aucunedonneeaafficher`)}
                      onRowClicked={(row, event) => {console.log('row clicked', row);
                      setDetailToShow(row);
                       
                       //   setshowmodal(true);
                      }}
                      paginationComponentOptions={{ rowsPerPageText: Outsidecomponenti18n(`commande.centredesadv.ligneparpage`) + ": ", rangeSeparatorText: Outsidecomponenti18n(`commande.centredesadv.du`), noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: Outsidecomponenti18n(`commande.centredesadv.tout`) }}
                  />
              </div>
          </div>

          
          <Modal
              open={isshowmodal}
              onClose={()=>setshowmodal(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              style={{overflow: 'scroll' }}

          >
              <div className="modal-dialog ">
                  <div className="modal-content">
                      <div className="modal-header">
                          <h4 className="modal-title">{Outsidecomponenti18n(`commande.centredesadv.detailsdufacture`)}</h4>
                          <button onClick={()=>setshowmodal(false)} type="button" className="close" data-dismiss="modal" aria-label={Outsidecomponenti18n(`commande.centredesadv.fermer`)}>
                              <span aria-hidden="true">&times;</span>
                          </button>
                      </div>
                      <div   className="modal-body">

                              
                         
                      </div>
                  </div>
              </div>
          </Modal>
          <Snackbar
              open={openToast.isOpenToast}
              autoHideDuration={6000}
              //onClose={()=>setOpenToast({...openToast, isOpenToast:false})}
              message={openToast.txt}
              action={<React.Fragment>
                  <IconButton
                      size="small"
                      aria-label={Outsidecomponenti18n(`commande.centredesadv.fermer`)}
                      color="inherit"
                      onClick={(event, reason) => {
                          if (reason === 'clickaway') {
                              return;
                          }
                          setOpenToast(false);
                      }}
                  >
                      <CloseIcon fontSize="small" />
                  </IconButton>
              </React.Fragment>}
          />
      </div>
          
      </LoadingOverlay>
  )
};
