import React, { createContext, useState} from 'react'
import Header from "../Header";
import Menu from "./Menu";
import {Centredesadv} from "./Centredesadv";
import Footer from "../Footer";



function Desadv () {

    return (
        <div className="wrapper">
            <Header/>
            <Menu/>
            <Centredesadv  />
            <Footer/>
        </div>
    );
}

export default Desadv;
