  // const baseUrl = "http://localhost:3001";
  // const baseUrlSocket = "http://localhost:3001/";

   const baseUrl = "https://my.globalnet.be";
   const baseUrlSocket = "https://my.globalnet.be/";

const googleapi = {
  baseurl: "https://maps.googleapis.com/maps/api/",
  token: "AIzaSyDPIlJCMr6sgNWSpegNcVXd76mcIuPPFhU"
};


export {
  baseUrl,
  googleapi,
  baseUrlSocket
};
