import React, { useEffect } from 'react'
import {Link} from "react-router-dom";
import ls from "local-storage";
import { useTranslation } from "react-i18next";
let isAdmin= ls.get('xxxx');

export default  ()=> {
  const { t, i18n } = useTranslation();

  let user_ = {nom: null, prenom : null}
  isAdmin = ls.get('xxxx');
  user_ =  JSON.parse( ls.get('user'));


    let lsi18n = ls.get('lsi18n');
  useEffect(() => {
      if(lsi18n !== null){
          i18n.changeLanguage( lsi18n,()=>null)
      }
    isAdmin = ls.get('xxxx');
     user_ =  JSON.parse( ls.get('user'));
  },[]);



    return (
      <aside style={{backgroundColor: '#2B4898'}} className="main-sidebar sidebar-dark-primary elevation-4">
          {/* Brand Logo */}
          <a style={{borderColor:'white',borderBottomWidth:1, borderBottomWidth:1}} href="/dashboard" className="brand-link">
            <img src={require("../../assets/logo-1002x1024.png").default} alt="myGlobalNet Logo" className="brand-image img-circle elevation-3" style={{opacity: '.8'}} />
            <span className="brand-text font-weight-light">myGlobalNet</span>
          </a>
          {/* Sidebar */}
          <div className="sidebar">
            {/* Sidebar user panel (optional) */}
            <div style={{borderColor:'white',borderTopWidth:1, width:'100%'}} className="user-panel mt-3 pb-3 mb-3 d-flex">
              <div className="info">
                <div  style={{ color:'#c2c7d0',cursor:"pointer"}} className="link">
                {t('menu.bonjour')}  <span>{((user_.nom != null && user_.nom != "")? user_.nom : '') + ' ' + ((user_.prenom != null && user_.prenom != "") ? user_.prenom : '')}</span>
                </div>
              </div>
            </div>
      {/* Sidebar Menu */}
      <nav className="mt-2">
        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
        <li className="nav-item">
            <a href="/dashboard/facture" className="nav-link">
              <i className="far fa-circle nav-icon" />
              <p>{t('menu.factures')}</p>
            </a>
          </li>
          <li className="nav-item">
            <a href="/dashboard/commande" className="nav-link">
              <i className="far fa-circle nav-icon" />
              <p>{t('menu.commandes')}</p>
            </a>
          </li>
          <li className="nav-header">{t('menu.configuration')}</li>
    
          {isAdmin === "AAAA" && <li className="nav-item">
            <div  className="nav-link">
              <i className="far fa-circle nav-icon" />
              <p>{t('menu.groupe')}</p>
            </div>
          </li>}
        {/* <li className="nav-item">
            <a href="/dashboard/message" className="nav-link">
              <i className="far fa-circle nav-icon" />
              <p> {t('menu.message')}</p>
            </a>
          </li> */}
           <li className="nav-header">{t('menu.contact')}</li>

          <li className="nav-item">
            <a href="https://about.globalnet.be/contact/" target='_blank' className="nav-link">
              <i className="far fa-circle nav-icon" />
              <p> {t('menu.nouscontacter')}</p>
            </a>
          </li>
          {/* {isAdmin === "AAAA"  && !user_.nomgroupe &&   <li className="nav-item">
            <div  style={{}} className="nav-link">
              <i className="far fa-circle nav-icon" />
              <p>Groupes(Villes)</p>
            </div>
          </li>}
        
          */}
         

        </ul>
      </nav>
      {/* /.sidebar-menu */}
    </div>
    {/* /.sidebar */}
  </aside>

        )
    
}
