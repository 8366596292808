
import { useTranslation } from "react-i18next";


export function Outsidecomponenti18n (text) {
    const { t } =  useTranslation();
    const translate = t(`${text}`);
  return  translate;
};

